import {useEffect} from 'react';
import {useAppDispatch} from '../../../../reducers/store';
import {MillionairesIntroductionPage} from './MillionairesIntroductionPage';
import {turnOnBackgroundMusic} from '../../../../reducers/appReducer';

export const MillionairesIntroductionPageContainer = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(turnOnBackgroundMusic());
    }, []);

    return <MillionairesIntroductionPage/>;
};
