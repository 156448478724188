import {combineReducers, configureStore} from '@reduxjs/toolkit'
import userReducer from "./userReducer.ts";
import appReducer from "./appReducer.ts";
import {useDispatch, useSelector} from "react-redux";
import errorReducer from "./errorReducer.ts";
import roomReducer from './roomReducer.ts';

import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import scenariosReducer from "./scenariosReducer.ts"; // defaults to localStorage for web

const rootReducer = combineReducers( {
    userSlice: userReducer,
    appSlice: appReducer,
    errorsSlice: errorReducer,
    roomSlice: roomReducer,
    scenariosSlice: scenariosReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export const persistor = persistStore(store)
