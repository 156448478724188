import {CenteredContentLayout} from "../../templates/CenteredContentLayout.tsx";
import {CosmonadaBoard, CosmonadaRow} from "../../organism/CosmonadaBoard.tsx";

interface Props {
    rows: CosmonadaRow[];
    question: string;
    wrongAnswer: boolean;
    handleAnswer: (answer: string) => void;
    isPlayerAnswering: boolean;
    username?: string;
    isSummary: boolean;
    lastAnswer?: string
}

export const CosmonadaRoundPage = ({rows, question, wrongAnswer, handleAnswer, isPlayerAnswering, username, isSummary, lastAnswer}: Props) => {
    return <CenteredContentLayout>
        <div className="flex flex-col h-full w-full sm:h-[90%] md:w-4/5 md:h-[90%]">
            <CosmonadaBoard rows={rows}
                            question={question}
                            wrongAnswer={wrongAnswer}
                            handleAnswer={handleAnswer}
                            isPlayerAnswering={isPlayerAnswering}
                            username={username}
                            isSummary={isSummary}
                            lastAnswer={lastAnswer}
            />
        </div>
    </CenteredContentLayout>
}