import { TypewritingText } from '../../../atom/TypewritingText.tsx';
import { Appear } from '../../../animations/Appear.tsx';
import { usePlaySound } from '../../../../hooks/usePlaySound.ts';

export const StepOne = () => {

    usePlaySound('/one_of_ten/intro_1.mp3', 0.6);

    return <Appear className="flex flex-col text-center gap-2 md:gap-8">
        <TypewritingText text={'Tę rundę zaczyna osoba z najmniejszą liczbą punktów.'} />
    </Appear>;
};
