import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';
import {GradientTitle} from '../molecule/GradientTitle.tsx';
import {User} from '../molecule/UserWithAvatar.tsx';
import {Place, Result} from '../molecule/ScoreCard.tsx';
import {ContinueButton} from '../molecule/ContinueButton.tsx';
import {ScoreCards} from "../organism/ScoreCards.tsx";

export interface RoundResult {
    user: User,
    place: Place,
    score: Result
}

interface Props {
    results: RoundResult[];
    handleClick: () => void;
}

export const GameSummaryPage = ({results, handleClick}: Props) => {

    return <CenteredContentLayout className="gap-8 md:gap-12">
        <GradientTitle text={'Podsumowanie Gry'}></GradientTitle>
        <ScoreCards scores={results}/>
        <ContinueButton onClick={() => {
            handleClick();
        }} text={'KONIEC'}></ContinueButton>
    </CenteredContentLayout>
};
