import {useLocation} from "wouter";
import {useEffect} from "react";
import {CenteredContentLayout} from "../../../templates/CenteredContentLayout.tsx";
import {GradientTitle} from "../../../molecule/GradientTitle.tsx";
import {StepOne} from "./StepOne.tsx";
import {StepTwo} from "./StepTwo.tsx";
import {StepFour} from "./StepFour.tsx";
import {Joke} from "./Joke.tsx";
import {StepFive} from "./StepFive.tsx";

interface Props {
    time: number;
}

export const CosmonadaIntroductionPage = ({time} : Props) => {
    const [_, setLocation] = useLocation();

    useEffect(() => {
        if (time > 34) {
            setLocation('/are-you-ready');
        }
    }, [time]);

    return (
        <CenteredContentLayout className="gap-2 md:gap-4">
            {time < 34 ? <GradientTitle text={'Kosmonada'} className="mx-auto hidden md:block"/> : <></>}
            {time < 6 ? <StepOne/> : <></>}
            {time >= 6 && time < 12 ? (<StepTwo/>) : (<></>)}
            {time >= 12 && time < 22 ? (<Joke/>) : (<></>)}
            {time >= 22 && time < 28 ? (
                <StepFour question={'Więcej niż jedno zwierzę to...'}/>
            ) : (
                <></>
            )}
            {time >= 28 && time < 34 ? (
                <StepFive question={'Więcej niż jedno zwierzę to...'}/>
            ) : (
                <></>
            )}
        </CenteredContentLayout>
    );
}