import {useTimer} from 'use-timer';
import {useEffect} from 'react';
import {GradientTitle} from '../../../molecule/GradientTitle.tsx';
import {AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {GuessTheSongAnswer, StepFour} from './StepFour.tsx';
import {StepThree} from './StepThree.tsx';
import {StepTwo} from './StepTwo.tsx';
import {StepOne} from './StepOne.tsx';
import {useLocation} from 'wouter';
import {CenteredContentLayout} from "../../../templates/CenteredContentLayout.tsx";

export const options: AnswerButtonsProps = {
    green: {
        text: 'Kora',
    },
    blue: {
        text: 'IRA',
    },
    yellow: {
        text: 'Dżem',
    },
    red: {
        text: 'Lady Pank',
    },
};

const answers: GuessTheSongAnswer[] = [
    {
        user: {
            userId: '123',
            avatarUrl: '/avatars/1.svg',
            name: 'Janek',
        },
        time: 1.15
    },
    {
        user: {
            userId: '234',
            avatarUrl: '/avatars/2.svg',
            name: 'Ewelina',
        },
        time: 4.37
    }
]

export const GuessTheSongIntroductionPage = () => {

    const {time, start} = useTimer();
    const [_, setLocation] = useLocation();

    useEffect(() => {
        start();
    }, [start]);

    useEffect(() => {
        if (time >= 28) {
            setLocation('/are-you-ready');
        }
    }, [time]);

    return <CenteredContentLayout className="gap-2 md:gap-4">
        {time < 28 ? <GradientTitle text={'Jaki To Zespół?'} className="mx-auto"/> : <></>}
        {time < 6 ? <StepOne/> : <></>}
        {time >= 6 && time < 14 ?
            <StepTwo answers={options}/> : <></>}
        {time >= 14 && time < 22 ?
            <StepThree answers={options}/> : <></>}
        {time >= 22 && time < 28 ?
            <StepFour answers={answers}/> : <></>}
    </CenteredContentLayout>;
};
