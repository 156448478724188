import { GradientTextMask } from '../atom/GradientTextMask.tsx';
import { VeryLargeText } from '../atom/VeryLargeText.tsx';

interface Props {
  text: string;
  className?: string;
}

export const VeryLargeGradientText = ({ text, className }: Props) => {
  return <GradientTextMask className={className}>
    <VeryLargeText textColor={null} text={text}></VeryLargeText>
  </GradientTextMask>;
};
