import {motion} from "framer-motion";
import {GradientTextMask} from "./GradientTextMask.tsx";

interface Props {
    text: string;
}

export const sentenceVariants = {
    hidden: {},
    // change staggerChildren variable to speed up or slow down typing.
    visible: {opacity: 1, transition: {staggerChildren: 0.04}}
};

export const letterVariants = {
    hidden: {opacity: 0},
    visible: {opacity: 1, transition: {opacity: {duration: 0}}}
};

export const GradientTypewritingText = ({text, ...rest}: Props) => (
    <GradientTextMask>
        <motion.p
            key={text}
            variants={sentenceVariants}
            initial="hidden"
            animate="visible"
            {...rest}
        >
            {text.split("").map((char, i) => (
                <motion.span key={`${char}-${i}`} className="text-md md:text-lg" variants={letterVariants}>
                    {char}
                </motion.span>
            ))}
        </motion.p>
    </GradientTextMask>
);
