import { Avatar } from '../atom/Avatar.tsx';
import { LargeText } from '../atom/LargeText.tsx';
import { LargeGradientText } from './LargeGradientText.tsx';
import { EditableAvatar } from './EditableAvatar.tsx';

export interface User {
    userId: string;
    name: string;
    avatarUrl: string;
}

interface Props {
    user: User;
    currentUser?: User;
    handleEditClick?: () => void;
}

export const UserWithAvatar = ({ user, currentUser, handleEditClick }: Props) => {
    return <div className="h-full flex flex-col justify-center items-center">
        {
            user.userId === currentUser?.userId ? (
                <EditableAvatar editClassName="-bottom-2 md:-bottom-6" onClick={handleEditClick}
                                className="h-12 w-12 md:h-16 md:w-16 md:mb-2" url={user.avatarUrl}></EditableAvatar>
            ) : <Avatar url={user.avatarUrl} className="h-12 w-12 md:h-16 md:w-16"></Avatar>
        }
        {
            user.userId === currentUser?.userId ? (<LargeGradientText text={user.name}></LargeGradientText>)
                : <LargeText text={user.name}></LargeText>
        }
    </div>;
};
