import {GradientTitle} from '../molecule/GradientTitle.tsx';
import {Link} from 'wouter';
import {EditableAvatar} from '../molecule/EditableAvatar.tsx';
import {Input} from '../atom/Input.tsx';
import {SmallText} from '../atom/SmallText.tsx';
import {SmallGradientText} from '../molecule/SmallGradientText.tsx';
import {ContinueButton} from '../molecule/ContinueButton.tsx';
import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';
import {useState} from 'react';
import {JoinRoomErrors} from '../../reducers/errorReducer.ts';
import {LargeText} from '../atom/LargeText.tsx';
import {Appear} from '../animations/Appear.tsx';

interface Props {
    username: string,
    avatar: string,
    handleEditClick: () => void,
    handleClick: (room: string) => void,
    errors?: JoinRoomErrors | undefined
}

export const CreateOrJoinPage = ({username, avatar, handleEditClick, handleClick, errors}: Props) => {

    const [roomName, setRoomName] = useState('');

    return <CenteredContentLayout className="md:gap-16">
        <div className="flex flex-col justify-center items-center" data-testid="welcome-banner">
            <EditableAvatar onClick={handleEditClick} url={avatar} editClassName="bottom-0"></EditableAvatar>
            <GradientTitle text={`Cześć ${username}!`}></GradientTitle>
        </div>
        <div>
            <Input placeholder={'Wpisz nazwę pokoju...'} onInputChanged={setRoomName}/>
            {
                errors?.chooseRoom ? <Appear className="mt-2"><LargeText textColor={'text-red'}
                                                                         text={errors.chooseRoom}/></Appear> : <></>
            }
        </div>
        <ContinueButton text="DALEJ" onClick={() => handleClick(roomName)}/>
        <div className="flex flex-col items-center text-center" data-testid="new-room-link">
            <SmallText text={'...lub stwórz'}></SmallText>
            <Link to={'/create-room'}>
                <SmallGradientText text={'nowy pokój'}/>
            </Link>
        </div>
    </CenteredContentLayout>;
};
