import {useState} from 'react';
import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {User} from '../../../molecule/UserWithAvatar.tsx';
import {UsersInGrid} from '../../../organism/UsersInGrid.tsx';
import {ContinueButton} from '../../../molecule/ContinueButton.tsx';
import {Appear} from '../../../animations/Appear.tsx';
import { usePlaySound } from '../../../../hooks/usePlaySound.ts';

const users: User[] = [
    {
        userId: '1',
        name: 'Andrzej',
        avatarUrl: '/avatars/1.svg',
    },
    {
        userId: '2',
        name: 'Ela',
        avatarUrl: '/avatars/2.svg',
    },
    {
        userId: '3',
        name: 'Józek',
        avatarUrl: '/avatars/3.svg',
    },
    {
        userId: '4',
        name: 'Kasia',
        avatarUrl: '/avatars/4.svg',
    },
];

export const StepTwo = () => {

    usePlaySound('/one_of_ten/intro_2.mp3', 0.6)
    const [selectedUser, setSelectedUser] = useState<User>(users[0]);

    return <Appear className="flex flex-col text-center w-3/4 mx-auto gap-4 md:gap-12">
        <TypewritingText
            text={'Wyznacza ona gracza do odpowiedzi. Poprawna odpowiedź daje 10 punktów, natomiast błąd kosztuje aż 20 punktów.'}/>
        <UsersInGrid className="w-3/4 md:w-1/2 mx-auto" users={users} onUserSelected={setSelectedUser}
                     cols={'2'}/>
        <ContinueButton className="mx-auto" text={'Wybieram'}
                        imageUrl={selectedUser.avatarUrl}></ContinueButton>
    </Appear>;
};
