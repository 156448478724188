import {cn} from "../../utils/cn.ts";

interface Props {
  url: string;
  className?: string;
  onClick?: () => void;
}

export const Avatar = ({url, className, onClick}: Props) => {
  return <img onClick={onClick} src={url} className={cn("w-16 h-16 md:w-24 md:h-24", className)}></img>
}
