import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {Appear} from '../../../animations/Appear.tsx';
import {MusicPlayingIcon} from '../../../atom/MusicPlayingIcon.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    answers: AnswerButtonsProps;
}

export const StepTwo = ({answers}: Props) => {

    usePlaySound('/guess_the_song/intro_2.mp3', 1.0)

    return <Appear className="w-5/6 text-center mx-auto gap-4 flex flex-col">
        <TypewritingText text={'Pierwszy gracz, który poprawnie odpowie na pytanie otrzyma aż 10 punktów!'}/>
        <div className="md:flex justify-center items-center hidden">
            <MusicPlayingIcon/>
        </div>
        <div className="flex flex-col items-center justify-center">
            <AnswerButtons {...answers} clickable={false}/>
        </div>
    </Appear>;
};
