import React, { Children, ReactElement } from 'react';
import { cn } from '../../utils/cn.ts';

interface Props {
  children: ReactElement | ReactElement[];
  className?: string;
  justify?: 'justify-between' | 'justify-around' | 'justify-center';
}

export const CenteredColumnLayout = ({ children, className, justify }: Props) => {
  return <div className={cn(`w-full h-full flex flex-col items-center ${justify ? justify : ''}`, className)}>
    {Children.map(children, child => (
      React.cloneElement(
        child,
        { style: { ...child.props.style } },
      )
    ))}
  </div>;
};
