import {BlurredBackground} from './BlurredBackground.tsx';
import {memo} from "react";

export const MusicPlayingIcon = memo(() => {
    return <div className="h-36 w-36 my-8">
        <BlurredBackground rounded={true} pulse={true}>
            <img className="absolute h-36 w-36" src="/music-playing.svg"/>
        </BlurredBackground>
    </div>
}, () => true)
