import { ChoosingPlayerToAnswer } from './ChoosingPlayerToAnswer.tsx';
import { User } from '../../molecule/UserWithAvatar.tsx';
import { CenteredContentLayout } from '../../templates/CenteredContentLayout.tsx';
import { GradientTitle } from '../../molecule/GradientTitle.tsx';
import { AnsweringQuestion } from './AnsweringQuestion.tsx';
import { AnswerButtonsProps } from '../../organism/AnswerButtons.tsx';
import { LevitatingImage } from '../../molecule/LevitatingImage.tsx';
import { LargeText } from '../../atom/LargeText.tsx';

type Mode = 'CHOOSING' | 'ANSWERING';

interface Props {
  mode: Mode;
  waiting: boolean;
  n: number;
  users: User[];
  question: string | null;
  answers: AnswerButtonsProps;
  username: string;
  onClick?: (user: User) => void;
}

export const OneOfNPage = ({ mode, waiting, n, users, question, answers, username, onClick }: Props) => {
  return (
    <CenteredContentLayout>
      <GradientTitle text={`Jeden z ${n}`} className="mx-auto" />
      {mode === 'CHOOSING' ? (
        <div className="w-full md:w-1/3">
          <ChoosingPlayerToAnswer onClick={onClick} users={users} waiting={waiting} />
        </div>
      ) : (
        <></>
      )}
      {question && mode === 'ANSWERING' ? (
        <div className="w-3/4 md:w-3/4">
          <AnsweringQuestion question={question} answers={answers} waiting={waiting} />
        </div>
      ) : (
        <></>
      )}
      {waiting ? (
        <div>
          <LevitatingImage
            animation={{ from: 0, to: -26, duration: 2 }}
            className="h-16 w-16 md:h-24 md:w-24 mx-auto"
            imageUrl={'/astronaut.svg'}
          />
          {mode === 'CHOOSING' ? (
            <LargeText text={`Wyznacza ${username}...`} />
          ) : (
            <LargeText text={`Odpowiada ${username}...`} />
          )}
        </div>
      ) : (
        <></>
      )}
    </CenteredContentLayout>
  );
};
