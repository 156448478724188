import {User} from '../molecule/UserWithAvatar.tsx';
import {ContinueButton} from '../molecule/ContinueButton.tsx';
import {LevitatingImage} from '../molecule/LevitatingImage.tsx';
import {LargeText} from '../atom/LargeText.tsx';
import {UsersInGrid} from './UsersInGrid.tsx';
import {TitleWithActions} from "./TitleWithActions.tsx";

interface Props {
    ownerId: string;
    currentUser: User;
    users: User[];
    roomName: string;
    handleUserEditClick?: () => void;
    handleStart: () => void;
    handleExit: () => void;
}

export const RoomBeforeGameStart = ({
                                        currentUser,
                                        ownerId,
                                        users,
                                        roomName,
                                        handleUserEditClick,
                                        handleStart,
                                        handleExit
                                    }: Props) => {

    return <>
        <TitleWithActions data-testid="room-title" title={roomName} roomName={roomName} handleExit={handleExit}/>
        <UsersInGrid handleUserEditClick={handleUserEditClick} users={users} currentUser={currentUser}/>
        {
            ownerId === currentUser.userId ? (
                <ContinueButton text={'START'} onClick={handleStart}></ContinueButton>
            ) : (
                <div className="flex flex-col justify-center items-center space-y-8">
                    <LevitatingImage imageUrl={'/astronaut.svg'} className="h-16 w-16 md:w-full md:h-full"
                                     animation={{from: 24, to: -20, duration: 2}}/>
                    <LargeText text={'Oczekiwanie aż gra się rozpocznie...'}/>
                </div>
            )
        }
    </>;
};
