import { User, UserWithAvatar } from '../molecule/UserWithAvatar.tsx';
import { GridLayout } from '../templates/GridLayout.tsx';
import { useEffect, useState } from 'react';

interface Props {
  users: User[];
  currentUser?: User;
  className?: string;
  cols?: string;
  onUserSelected?: (user: User) => void;
  handleUserEditClick?: () => void;
}

export const UsersInGrid = ({users, currentUser, className, cols = '3', onUserSelected, handleUserEditClick}: Props) => {

  const [selectedUser, setSelectedUser] = useState<User | undefined>(currentUser);

  useEffect(() => {
    handleUserSelected(users[0])
  }, [users]);

  const handleUserSelected = (user: User) => {
    if(onUserSelected) {
      onUserSelected(user)
      setSelectedUser(user)
    }
  }


  return <GridLayout className={className} cols={cols}>
    {users.map(user => (
      <div key={user.userId} className={user.userId !== selectedUser?.userId && onUserSelected ? `cursor-pointer grayscale` : ``}
           onClick={() => handleUserSelected(user)}>
        <UserWithAvatar handleEditClick={handleUserEditClick} currentUser={currentUser} user={user} />
      </div>
    ))}
  </GridLayout>
}
