import {RoundResult} from './RoundSummaryPage.tsx';
import {selectRoom, updateRoom, updateRoomName} from '../../reducers/roomReducer.ts';
import {useSelector} from 'react-redux';
import {GameSummaryPage} from './GameSummaryPage.tsx';
import {useQuizApi} from "../../client/useQuizApi.ts";
import {useAppDispatch} from '../../reducers/store.ts';
import {useLocation} from 'wouter';

export const GameSummaryPageContainer = () => {

    const room = useSelector(selectRoom);

    const [_, setLocation] = useLocation();
    const {leaveRoom} = useQuizApi();
    const dispatch = useAppDispatch()

    if (room === undefined) {
        return <></>;
    }

    const results: RoundResult[] = room.users
        .map((u, index) => (
            {
                user: u.user,
                place: index + 1,
                score: u.score.toString(),
            }
        ));

    return <GameSummaryPage results={results}
                            handleClick={async () => {
                                leaveRoom(room?.name ?? '').then(() => {
                                    dispatch(updateRoomName(''))
                                    dispatch(updateRoom(undefined))
                                    setLocation('/create-or-join')
                                })
                            }}/>;
};
