import { UserAnswer } from '../reducers/roomReducer.ts';

export const mapUserAnswersToRecord = (answers: UserAnswer[]): Record<string, string[]> => {
  return answers.reduce((acc, curr) => {
    if (!acc[curr.answer.toString()]) {
      acc[curr.answer.toString()] = [];
    }
    acc[curr.answer.toString()].push(curr.username);
    return acc;
  }, {} as Record<string, string[]>);
};

