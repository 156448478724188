import { BlurredBackground } from '../atom/BlurredBackground.tsx';
import { Button } from '../atom/Button.tsx';

interface Props {
  text?: string;
  onClick?: () => void;
  className?: string;
  imageUrl?: string | null;
}

export const GradientButton = ({text, onClick, imageUrl, className}: Props) => {
  return <BlurredBackground>
    <Button text={text}
            onClick={onClick}
            className={`absolute ${className}`}
            imageUrl={imageUrl}
    />
  </BlurredBackground>
}
