import { useTimer } from 'use-timer';
import { useEffect } from 'react';
import { GradientTitle } from '../../../molecule/GradientTitle.tsx';
import { AnswerButtonsProps } from '../../../organism/AnswerButtons.tsx';
import { StepFour } from './StepFour.tsx';
import { StepThree } from './StepThree.tsx';
import { StepTwo } from './StepTwo.tsx';
import { StepOne } from './StepOne.tsx';
import { useLocation } from 'wouter';
import { CenteredContentLayout } from '../../../templates/CenteredContentLayout.tsx';

export const answers: AnswerButtonsProps = {
  green: {
    text: 'Paryż',
  },
  blue: {
    text: 'Warszawa',
  },
  yellow: {
    text: 'Moskwa',
  },
  red: {
    text: 'Sarajewo',
  },
};

export const MillionairesIntroductionPage = () => {
  const { time, start } = useTimer();
  const [_, setLocation] = useLocation();

  useEffect(() => {
    start();
  }, [start]);

  useEffect(() => {
    if (time > 18) {
      setLocation('/are-you-ready');
    }
  }, [time]);

  return (
    <CenteredContentLayout className="gap-2 md:gap-4">
      {time < 18 ? <GradientTitle text={'Miliarderzy'} className="mx-auto" /> : <></>}
      {time < 4 ? <StepOne /> : <></>}
      {time >= 4 && time < 8 ? (
        <StepTwo question={'Które z wymienionych miast jest stolicą Francji?'} answers={answers} />
      ) : (
        <></>
      )}
      {time >= 8 && time < 12 ? (
        <StepThree question={'Które z wymienionych miast jest stolicą Francji?'} answers={answers} />
      ) : (
        <></>
      )}
      {time >= 12 && time < 18 ? (
        <StepFour question={'Które z wymienionych miast jest stolicą Francji?'} answers={answers} />
      ) : (
        <></>
      )}
    </CenteredContentLayout>
  );
};
