import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {VeryLargeGradientText} from '../../../molecule/VeryLargeGradientText.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    question: string;
    answers: AnswerButtonsProps;
}

export const StepTwo = ({question, answers}: Props) => {

    usePlaySound('/millionaires/intro_2.mp3', 0.6)

    return <div className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText text={'Za każdą dobrą odpowiedź otrzymasz 5 punktów.'}/>
        <VeryLargeGradientText className="mx-auto" text={question}/>
        <AnswerButtons {...answers} clickable={false}/>
    </div>
};
