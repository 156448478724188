import {useSelector} from 'react-redux';
import {selectRoom} from '../reducers/roomReducer.ts';
import {useEffect} from 'react';
import {useLocation} from 'wouter';

export const useRedirections = () => {
    const room = useSelector(selectRoom)
    const [location, setLocation] = useLocation();

    const millionairesRedirections = () => {
        if (room) {
            if (['ROUND_INTRODUCTION', 'ROUND_STARTED'].includes(room.state)) {
                setLocation('/millionaires/introduction');
            } else {
                setLocation('/millionaires');
            }
        }
    };

    const guessTheSongRedirections = () => {
        if (room) {
            if (['ROUND_INTRODUCTION', 'ROUND_STARTED'].includes(room.state)) {
                setLocation('/guess-the-song/introduction');
            } else {
                setLocation('/guess-the-song');
            }
        }
    }

    const oneOfNRedirections = () => {
        if (room) {
            if (['ROUND_INTRODUCTION', 'ROUND_STARTED'].includes(room.state)) {
                setLocation('/one-of-n/introduction');
            } else {
                setLocation('/one-of-n');
            }
        }
    }

    const cosmonadaRedirections = () => {
        if (room) {
            if (room.question == null && room.state !== 'ROUND_FINISHED' && room.state !== 'GAME_FINISHED') {
                setLocation('/cosmonada/introduction');
            } else {
                setLocation('/cosmonada');
            }
        }
    }

    useEffect(() => {
        if(location.includes('are-you-ready')
            && (room?.state === 'ROUND_INTRODUCTION' || room?.state === 'COSMONADA_BOARD_UPDATED')) {
            return;
        }
        if (room) {
            if (room.state === 'GAME_FINISHED') {
                setLocation('/game-summary')
            } else if (room.state === 'ROUND_FINISHED') {
                setLocation('/round-summary')
            } else if (room.roundType === 'MILLIONAIRES') {
                millionairesRedirections();
            } else if (room.roundType === 'GUESS_THE_SONG') {
                guessTheSongRedirections();
            } else if (room.roundType === 'ONE_OF_TEN') {
                oneOfNRedirections();
            } else if (room.roundType === 'COSMONADA') {
                cosmonadaRedirections();
            }
        }
    }, [location, room]);

}
