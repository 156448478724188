import {RoundResult, RoundSummaryPage} from './RoundSummaryPage.tsx';
import {selectRoom} from '../../reducers/roomReducer.ts';
import {useSelector} from 'react-redux';
import {usePlaySound} from "../../hooks/usePlaySound.ts";

export const RoundSummaryPageContainer = () => {

    const room = useSelector(selectRoom);
    usePlaySound('/fanfare.mp3', 0.3)

    if (room === undefined) {
        return <></>;
    }

    const results: RoundResult[] = room.users
        .map((u, index) => (
            {
                user: u.user,
                place: index + 1,
                score: u.score.toString(),
            }
        ));

    return <RoundSummaryPage results={results}
    />;
};
