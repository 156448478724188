import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {User} from '../../../../reducers/userReducer.ts';
import {Appear} from "../../../animations/Appear.tsx";
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";
import {ScoreCards} from "../../../organism/ScoreCards.tsx";

export interface GuessTheSongAnswer {
    user: User,
    time: number
}

interface Props {
    answers: GuessTheSongAnswer[];
}

export const StepFour = ({answers}: Props) => {

    usePlaySound('/guess_the_song/intro_4.mp3', 1.0)

    return <Appear className="flex flex-col w-5/6 gap-4 md:gap-12 text-center justify-center items-center">
        <TypewritingText text={'Po każdej piosence dowiecie się kto najszybciej odpowiedział.'}/>
        <ScoreCards scores={answers.map((ans, index) => ({
            user: ans.user,
            place: index + 1,
            score: ans.time.toFixed(2) + ' s'
        }))}></ScoreCards>
    </Appear>;
};
