import {Button} from '../atom/Button.tsx';
import {Avatar} from '../atom/Avatar.tsx';
import {cn} from '../../utils/cn.ts';

interface Props {
    onClick?: () => void;
    className?: string
}

export const EditButton = ({onClick, className}: Props) => {
    return <div className={cn("h-6 w-6 md:h-8 md:w-8 rounded-full bg-primary-gradient p-0.5", className)}>
        <Button onClick={onClick} className="flex items-center justify-center relative">
            <Avatar className="absolute max-w-3 md:max-w-4" url="/edit-icon.svg"/>
        </Button>
    </div>
}
