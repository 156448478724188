import {RoomPage} from './RoomPage.tsx';
import {useQuizApi} from '../../client/useQuizApi.ts';
import {useLocation, useParams} from 'wouter';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectUser} from '../../reducers/userReducer.ts';
import {selectRoom, selectRoomName, updateRoomName} from '../../reducers/roomReducer.ts';
import {useAppDispatch} from "../../reducers/store.ts";
import {updateAnchor} from "../../reducers/appReducer.ts";

export const RoomPageContainer = () => {

    const params = useParams();
    const roomNameFromParam: string = params.room ?? '';

    const [_, setLocation] = useLocation();
    const dispatch = useAppDispatch()
    const {currentUser, joinRoom, leaveRoom} = useQuizApi();

    const {startGame} = useQuizApi();
    const user = useSelector(selectUser);
    const room = useSelector(selectRoom);
    const roomName = useSelector(selectRoomName) ?? ''

    const handleStart = () => {
        startGame(roomName);
    };

    const handleExit = () => {
        leaveRoom(roomName)
        setLocation('/create-or-join')
    };

    useEffect(() => {
        currentUser();
    }, [roomName]);

    useEffect(() => {
        dispatch(updateRoomName(roomNameFromParam))
    }, [roomNameFromParam]);

    useEffect(() => {
        if (roomNameFromParam && user && user.userId !== 'spectator') {
            joinRoom(roomNameFromParam)
        }
    }, [roomNameFromParam, user]);

    if (!room || !user) {
        return null;
    }

    return <RoomPage
        handleUserEditClick={() => {
            dispatch(updateAnchor(`/room/${roomNameFromParam}`))
            setLocation('/')
        }}
        roomName={roomNameFromParam}
        ownerId={room.ownerId}
        currentUser={user}
        users={room.users.map(user => user.user)}
        handleStart={handleStart}
        handleExit={handleExit}
    />;
};
