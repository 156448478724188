import {useEffect} from "react";
import {turnOnBackgroundMusic} from "../../../../reducers/appReducer.ts";
import {GuessTheSongIntroductionPage} from "./GuessTheSongIntroductionPage.tsx";
import {useAppDispatch} from "../../../../reducers/store.ts";

export const GuessTheSongIntroductionPageContainer = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(turnOnBackgroundMusic());
    }, []);

    return <GuessTheSongIntroductionPage/>
}