import {MillionairesRoundPage} from './MillionairesRoundPage.tsx';
import {useSelector} from 'react-redux';
import {MillionairesQuestion, selectRoom} from '../../../reducers/roomReducer.ts';
import {useQuizApi} from '../../../client/useQuizApi.ts';
import {mapUserAnswersToRecord} from '../../../utils/mapUserAnswersToRecord.ts';
import {useEffect} from "react";
import useSound from "use-sound";
import {selectUser} from "../../../reducers/userReducer.ts";

export const MillionairesRoundPageContainer = () => {

    const room = useSelector(selectRoom);
    const {answerQuestion} = useQuizApi();
    const [play, {stop}] = useSound('/millionaires/thinking-background.mp3', { volume: 0.1, interrupt: true });

    const user = useSelector(selectUser)

    useEffect(() => {
        if(room?.state == "QUESTION_ASKED" && user && user.userId === 'spectator') {
            play()
        }

        return () => {
            stop()
        }
    }, [play, room?.state, user])

    if (!room || !room.question) {
        return <></>;
    }

    const question = room.question as MillionairesQuestion;

    return <MillionairesRoundPage
        question={question.text}
        answers={{
            onAnswerSelected: (answer: string) => {
                answerQuestion(room.name, room.roundType ?? '', answer, question.id);
            },
            correctAnswer: question.correctAnswer ?? undefined,
            answers: {
                answerToUsers: 'QUESTION_FINISHED' === room.state ? mapUserAnswersToRecord(room.answers) : {},
            },
            green: {
                text: room.question.optionA,
            },
            blue: {
                text: room.question.optionB,
            },
            yellow: {
                text: room.question.optionC,
            },
            red: {
                text: room.question.optionD,
            },
        }}
    />;
};
