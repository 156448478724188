import { GradientTextMask } from '../atom/GradientTextMask.tsx';
import { LargeText } from '../atom/LargeText.tsx';

interface Props {
  text: string;
  className?: string
}

export const LargeGradientText = ({ text, className }: Props) => {
  return <GradientTextMask>
    <LargeText className={className} textColor={null} text={text}></LargeText>
  </GradientTextMask>;
};
