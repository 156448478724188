import {WelcomePage} from './WelcomePage.tsx';
import {useLocation} from 'wouter';
import {useAppDispatch, useAppSelector} from '../../reducers/store.ts';
import {selectUser, updateUser} from '../../reducers/userReducer.ts';
import {useEffect, useState} from 'react';
import {useQuizApi} from '../../client/useQuizApi.ts';
import {useSelector} from 'react-redux';
import {selectAnchor, updateAnchor} from "../../reducers/appReducer.ts";
import {selectChooseUsernameAndAvatarErrors, usernameWithBlankName} from "../../reducers/errorReducer.ts";

export const WelcomePageContainer = () => {

    const [_, setLocation] = useLocation()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const anchor = useSelector(selectAnchor)
    const [username, setUsername] = useState<string>('')
    const errors = useSelector(selectChooseUsernameAndAvatarErrors)

    const [avatarUrl, setAvatarUrl] = useState<string>(`/avatars/1.svg`)

    useEffect(() => {
        if (user?.userId === 'spectator') {
            dispatch(updateUser(undefined))
        }

        if (user) {
            setUsername(user.name)
            setAvatarUrl(user.avatarUrl)
        }
    }, [user]);

    const {chooseUsernameAndAvatar} = useQuizApi()

    const handleClick = () => {
        chooseUsernameAndAvatar(username, avatarUrl)
            .then((res) => {
                if (!res.ok) {
                    dispatch(usernameWithBlankName())
                    return;
                }
                if (anchor) {
                    const link = anchor
                    dispatch(updateAnchor(undefined))
                    setLocation(link)
                } else {
                    setLocation('/create-or-join')
                }
            })
    };


    return <WelcomePage
        handleClick={handleClick}
        username={username}
        avatarUrl={avatarUrl}
        setAvatarUrl={setAvatarUrl}
        setUsername={setUsername}
        errors={errors}
    />
}
