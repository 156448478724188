import {GradientButton} from './GradientButton.tsx';
import {useKeyboardShortcut} from "../../hooks/useKeyboardShortcut.ts";

interface Props {
    text?: string;
    imageUrl?: string | null;
    onClick?: () => void;
    className?: string;
}

export const ContinueButton = ({text, imageUrl = '/astronaut.svg', onClick, className}: Props) => {

    useKeyboardShortcut({
        key: 'Enter', onKeyPressed: onClick ?? (() => {
        })
    })

    return <div data-testid="continue-button" className={`h-12 w-36 md:h-16 md:w-48 ${className}`}>
        <GradientButton text={text}
                        imageUrl={imageUrl}
                        onClick={onClick}
        />
    </div>;
};
