import {Route} from 'wouter';
import {WelcomePageContainer} from './components/pages/WelcomePageContainer.tsx';
import {CreateOrJoinPageContainer} from './components/pages/CreateOrJoinPageContainer.tsx';
import {CreateRoomPageContainer} from './components/pages/CreateRoomPageContainer.tsx';
import {RoomPageContainer} from './components/pages/RoomPageContainer.tsx';
import {useRoomWebsocket} from './client/useRoomWebsocket.ts';
import {useRedirections} from './hooks/useRedirections.tsx';
import {MillionairesRoundPageContainer} from './components/pages/millionaires/MillionairesRoundPageContainer.tsx';
import {RoundSummaryPageContainer} from './components/pages/RoundSummaryPageContainer.tsx';
import {GuessTheSongPageContainer} from './components/pages/guessthesong/GuessTheSongPageContainer.tsx';
import {OneOfNPageContainer} from './components/pages/oneofn/OneOfNPageContainer.tsx';
import {GameSummaryPageContainer} from './components/pages/GameSummaryPageContainer.tsx';
import {useEffect} from 'react';
import {useQuizApi} from './client/useQuizApi.ts';
import {
    MillionairesIntroductionPageContainer
} from './components/pages/millionaires/introduction/MillionairesIntroductionPageContainer.tsx';
import {useBackgroundMusic} from './hooks/useBackgroundMusic.ts';
import {AreYouReadyPageContainer} from './components/pages/AreYouReadyPageContainer.tsx';
import {InvitationPageContainer} from "./components/pages/InvitationPageContainer.tsx";
import {
    GuessTheSongIntroductionPageContainer
} from "./components/pages/guessthesong/introduction/GuessTheSongIntroductionPageContainer.tsx";
import {
    OneOfNIntroductionPageContainer
} from "./components/pages/oneofn/introduction/OneOfNIntroductionPageContainer.tsx";
import {CosmonadaPageContainer} from "./components/pages/cosmonada/CosmonadaPageContainer.tsx";
import {
    CosmonadaIntroductionPageContainer
} from "./components/pages/cosmonada/introduction/CosmonadaIntroductionPageContainer.tsx";

function App() {
    const {currentUser} = useQuizApi();

    useEffect(() => {
        currentUser();
    }, []);

    useBackgroundMusic();
    useRoomWebsocket();
    useRedirections();

    return (
        <div className="absolute z-10">
            <Route path={'/'} component={WelcomePageContainer}/>
            <Route path={'/create-or-join'} component={CreateOrJoinPageContainer}/>
            <Route path={'/create-room'} component={CreateRoomPageContainer}/>
            <Route path={'/room/:room'} component={RoomPageContainer}/>
            <Route path={'/invitation/:room'} component={InvitationPageContainer}/>
            <Route path={'/are-you-ready'} component={AreYouReadyPageContainer}/>
            <Route path={'/millionaires/introduction'} component={MillionairesIntroductionPageContainer}/>
            <Route path={'/millionaires'} component={MillionairesRoundPageContainer}/>
            <Route path={'/guess-the-song/introduction'} component={GuessTheSongIntroductionPageContainer}/>
            <Route path={'/guess-the-song'} component={GuessTheSongPageContainer}/>
            <Route path={'/one-of-n/introduction'} component={OneOfNIntroductionPageContainer}/>
            <Route path={'/one-of-n'} component={OneOfNPageContainer}/>
            <Route path={'/cosmonada'} component={CosmonadaPageContainer}></Route>
            <Route path={'/cosmonada/introduction'} component={CosmonadaIntroductionPageContainer}></Route>
            <Route path={'/round-summary'} component={RoundSummaryPageContainer}></Route>
            <Route path={'/game-summary'} component={GameSummaryPageContainer}></Route>
        </div>
    );
}

export default App;
