import { EditableElement } from './EditableElement.tsx';
import { Avatar } from '../atom/Avatar.tsx';

interface Props {
  url: string;
  onClick?: () => void;
  className?: string;
  editClassName?: string;
}

export const EditableAvatar = ({url, onClick, className, editClassName}: Props) => {
  return <EditableElement className={className} editClassName={editClassName} onClick={onClick}>
    <Avatar url={url}></Avatar>
  </EditableElement>
}
