import {motion} from 'framer-motion';
import {ReactNode, useState} from 'react';

interface Props {
    className?: string;
    children?: ReactNode;
    rounded?: boolean;
    color?: string;
    pulse?: boolean;
    withoutHover?: boolean
}

export const BlurredBackground = ({
                                      className,
                                      children,
                                      rounded,
                                      color = 'primary-gradient',
                                      pulse = false,
                                      withoutHover = false
                                  }: Props) => {

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const Animation = ({className}: { className: string }) => pulse ? (
        <motion.div
            className={className}
            initial={{
                scale: 1,
            }}
            animate={{
                scale: withoutHover ? 1 : 1.4,
            }}
            transition={{
                ease: 'easeInOut',
                repeat: Infinity,
                repeatType: 'mirror',
                duration: 1,
            }}>
        </motion.div>
    ) : (
        <motion.div
            className={className}
            initial={false}
            animate={{
                scale: isHovered && !withoutHover ? 1.1 : 1,
                transition: {duration: 0.2},
            }}>
        </motion.div>
    );

    return <div
        className={`relative h-full w-full flex items-center justify-center ${className}`}
        onMouseEnter={() => !pulse && setIsHovered(true)}
        onMouseLeave={() => !pulse && setIsHovered(false)}
    >
        <Animation
            className={`absolute bg-${color} blur-primary-bg-hovered ${rounded ? 'w-4/5 h-4/5 rounded-full' : 'w-full h-full'}`}>
        </Animation>
        {children}
    </div>;
};
