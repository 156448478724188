import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store.ts";

interface AvailableScenario {
    label: string,
    value: string
}

interface AvailableScenarios {
    scenarios: AvailableScenario[]
}

const initialState: AvailableScenarios = {
    scenarios: [],
}

export const scenariosSlice = createSlice({
    name: 'scenariosSlice',
    initialState: initialState,
    reducers: {
        updateAvailableScenarios: (state, action: PayloadAction<string[]>) => {
            state.scenarios = [...action.payload.map(name => ({label: name, value: name})), ({
                label: "Wygeneruj nowy",
                value: "GENERATE"
            })]
        },
    },
})

// Action creators are generated for each case reducer function
export const {updateAvailableScenarios} = scenariosSlice.actions

export const selectAvailableScenarios = (state: RootState) => state.scenariosSlice.scenarios

export default scenariosSlice.reducer

