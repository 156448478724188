import { Avatar } from '../atom/Avatar.tsx';
import { BlurredBackground } from '../atom/BlurredBackground.tsx';

interface Props {
  avatarUrl: string;
  className?: string;
  rounded?: boolean;
  onClick?: () => void;
}

export const GradientBackgroundAvatar = ({ avatarUrl, className, rounded, onClick }: Props) => {
  return <BlurredBackground className={`cursor-pointer`} rounded={rounded}>
    <Avatar className={`absolute ${className}`} url={avatarUrl} onClick={onClick} />
  </BlurredBackground>;
};
