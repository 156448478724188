import {CenteredContentLayout} from '../../templates/CenteredContentLayout.tsx';
import {GradientTitle} from '../../molecule/GradientTitle.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../organism/AnswerButtons.tsx';
import {Timer} from '../../molecule/Timer.tsx';
import {MusicPlayingIcon} from '../../atom/MusicPlayingIcon.tsx';
import {UserAnswer} from '../../../reducers/roomReducer.ts';
import {ScoreCard} from '../../molecule/ScoreCard.tsx';
import {usePlaySoundIf} from "../../../hooks/usePlaySound.ts";

interface Props {
    answers: AnswerButtonsProps;
    songUrl: string;
    timer: number;
    providedAnswers?: UserAnswer[];
}

export const GuessTheSongPage = ({answers, songUrl, timer, providedAnswers}: Props) => {
    usePlaySoundIf(songUrl, 0.6, () => !providedAnswers, [providedAnswers])

    return (
        <CenteredContentLayout>
            <GradientTitle text={'Jaki To Zespół?'} className="mx-auto"/>
            <div className="hidden md:flex justify-center">
                <MusicPlayingIcon/>
            </div>
            {!providedAnswers ? <AnswerButtons className={'w-3/4'} {...answers} /> : <></>}
            {providedAnswers ? (
                <div className="flex flex-col w-3/4 lg:w-1/2 gap-8 overflow-auto mx-auto">
                    {providedAnswers &&
                        providedAnswers
                            .filter((ans) => ans.answer === answers.correctAnswer)
                            .map((ans, index) => (
                                <ScoreCard
                                    user={{userId: ans.userId, name: ans.username, avatarUrl: ans.avatarUrl}}
                                    place={index + 1}
                                    score={(ans.timeForAnswerInMs / 1000).toFixed(2) + ' s'}
                                />
                            ))
                    }
                    {
                        providedAnswers &&
                        providedAnswers
                            .filter((ans) => ans.answer === answers.correctAnswer)
                            .length == 0 &&
                        <div className="flex justify-center items-center">
                            <GradientTitle text={"Brak poprawnych odpowiedzi 🤷"}/>
                        </div>
                    }
                </div>
            ) : (
                <></>
            )}
            <div className="flex justify-center w-3/4">
                {!providedAnswers && <Timer name={songUrl} totalTimeInSeconds={timer}/>}
            </div>
        </CenteredContentLayout>
    );
};
