import {CosmonadaIntroductionPage} from "./CosmonadaIntroductionPage.tsx";
import {useEffect} from "react";
import {turnOnBackgroundMusic} from "../../../../reducers/appReducer.ts";
import {useAppDispatch} from "../../../../reducers/store.ts";
import {useTimer} from "use-timer";

export const CosmonadaIntroductionPageContainer = () => {

    const dispatch = useAppDispatch()
    const {time, start} = useTimer();


    useEffect(() => {
        start();
    }, [start]);

    useEffect(() => {
        dispatch(turnOnBackgroundMusic());
    }, []);

    return <CosmonadaIntroductionPage time={time}/>
}