import { GradientTextMask } from '../atom/GradientTextMask.tsx';
import { SmallText } from '../atom/SmallText.tsx';

interface Props {
  text: string;
  className?: string
}

export const SmallGradientText = ({text, className}: Props) => {
  return <GradientTextMask className={className}>
    <SmallText textColor={null} text={text}></SmallText>
  </GradientTextMask>
}
