import { ReactNode } from 'react';
import { EditButton } from './EditButton.tsx';
import { cn } from '../../utils/cn.ts';

interface Props {
    children: ReactNode;
    onClick?: () => void;
    className?: string;
    editClassName?: string;
}

export const EditableElement = ({ children, onClick, className, editClassName}: Props) => {
    return <div className={`relative flex justify-center ${className}`}>
        {children}
        <EditButton className={cn('absolute right-0 -bottom-2', editClassName)} onClick={onClick}></EditButton>
    </div>;
};
