import {CenteredContentLayout} from "../templates/CenteredContentLayout.tsx";
import {ContinueButton} from "../molecule/ContinueButton.tsx";
import {GradientTitle} from "../molecule/GradientTitle.tsx";
import {LevitatingImage} from "../molecule/LevitatingImage.tsx";

interface Props {
    handleJoinAsSpectator: () => void;
    handleJoinAsPlayer: () => void;
}

export const InvitationPage = ({handleJoinAsPlayer, handleJoinAsSpectator}: Props) => {
    return <CenteredContentLayout>
        <GradientTitle text={"Dołączam do pokoju jako..."}/>
        <div className="contents md:flex md:justify-between md:w-1/2 lg:w-1/3">
            <ContinueButton onClick={handleJoinAsPlayer} text="GRACZ" imageUrl={null}></ContinueButton>
            <ContinueButton onClick={handleJoinAsSpectator} text="WIDZ" imageUrl={null}></ContinueButton>
        </div>
        <LevitatingImage imageUrl={'/astronaut.svg'} className="h-16 w-16 md:w-full md:h-full"
                         animation={{from: 24, to: -20, duration: 2}}/>
    </CenteredContentLayout>
}