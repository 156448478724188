import {Avatar} from '../atom/Avatar.tsx';
import {User} from './UserWithAvatar.tsx';
import {LargeText} from '../atom/LargeText.tsx';
import {cn} from "../../utils/cn.ts";

export type Result = string
export type Place = number

interface Props {
    user: User,
    place: Place,
    score: Result,
    className?: string
}

export const ScoreCard = ({user, place, score, className}: Props) => {

    const getBackground = () => {
        switch (place) {
            case 1:
                return 'bg-gold-gradient';
            case 2:
                return 'bg-silver-gradient';
            case 3:
                return 'bg-bronze-gradient';
            default:
                return 'bg-white';
        }
    };

    const background = getBackground();

    return <div className={cn(`flex justify-between ${background} rounded-3xl px-2 md:px-4 h-16 md:h-24`, className)}>
        <div className="flex justify-between items-center">
            <Avatar className="w-12 h-12 md:w-18 md:h-18" url={user.avatarUrl}></Avatar>
            <LargeText className="ml-4" textColor="black" text={user.name}/>
        </div>
        <div className="flex justify-center items-center pr-8">
            <LargeText textColor="black" text={score.toString()}/>
        </div>
    </div>;
};
