import {useAppDispatch} from "../../../../reducers/store.ts";
import {useEffect} from "react";
import {turnOnBackgroundMusic} from "../../../../reducers/appReducer.ts";
import {OneOfNIntroductionPage} from "./OneOfNIntroductionPage.tsx";

export const OneOfNIntroductionPageContainer = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(turnOnBackgroundMusic());
    }, []);


    return <OneOfNIntroductionPage/>
}