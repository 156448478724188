import { Children, ReactNode } from 'react';

interface Props {
  children?: ReactNode | ReactNode[];
  className?: string;
  cols?: string;
}

export const GridLayout = ({ children, className, cols = '3' }: Props) => {
  return <div className={`grid grid-cols-${cols} gap-8 ${className}`}>
    {Children.map(children, (child, index) => (
      <div className="flex justify-center" key={index}>
        {child}
      </div>
    ))}
  </div>;
};
