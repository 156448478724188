import {useAppDispatch} from "../reducers/store.ts";
import {selectUser, updateUser} from "../reducers/userReducer.ts";
import {
    clearErrors,
    duplicatedRoomNameError,
    roomDoesNotExistError, roomWithBlankName,
    scenarioDoesNotExist
} from "../reducers/errorReducer.ts";
import {updateRoom, updateRoomName} from "../reducers/roomReducer.ts";
import {updateAvailableScenarios} from "../reducers/scenariosReducer.ts";
import {useLocation} from 'wouter';
import {useSelector} from "react-redux";

export const useQuizApi = () => {

    const BASE_URL = import.meta.env.VITE_QUIZ_API_URL + "/api";

    const dispatch = useAppDispatch()
    const [location, setLocation] = useLocation()
    const user = useSelector(selectUser)

    const currentUser = async () => {
        return fetch(`${BASE_URL}/user/me`, {
            'credentials': 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(async result => {
                if (result.status === 200 && user?.userId !== 'spectator') {
                    const user = await result.json()
                    dispatch(updateUser(user))
                }

                if (result.status !== 200 && (user?.userId !== 'spectator') && !location.includes('invitation')) {
                    dispatch(updateUser(undefined))
                    setLocation('/')
                }
            }).catch((_) => {
                dispatch(updateUser(undefined))
                setLocation('/')
            })
    }

    const getRoom = async (roomName: string) => {
        return fetch(`${BASE_URL}/room/${roomName}`, {
            'credentials': 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(async result => {
                if (result.status === 200) {
                    const room = await result.json()
                    dispatch(updateRoom(room))
                } else if (result.status === 404) {
                    dispatch(updateRoom(undefined))
                }
            })
    }

    const chooseUsernameAndAvatar = async (username: string, avatarUrl: string) => {
        return fetch(`${BASE_URL}/user`, {
            'credentials': 'include',
            method: 'POST',
            body: JSON.stringify({name: username, avatarUrl: avatarUrl}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }

    const joinRoom = async (roomName: string) => {
        return fetch(`${BASE_URL}/room/${roomName}/join`, {
            'credentials': 'include',
            method: 'POST',
        }).then(async result => {
            if (result.status === 200) {
                dispatch(updateRoomName(roomName))
                setLocation(`/room/${roomName}`)
            }
            if (result.status === 400) {
                const error = await result.json()
                if (error.result === "UserAlreadyInRoom") {
                    setLocation(`/room/${roomName}`)
                }
                if (error.result === "RoomDoesNotExist") {
                    if(location.includes('create-or-join')) {
                        dispatch(roomDoesNotExistError())
                    } else {
                        setLocation('/create-or-join')
                    }
                }
                if (error.result === "UserWithoutName") {
                    setLocation('/')
                }
            }
        })
    }

    const leaveRoom = async (roomName: string) => {
        return fetch(`${BASE_URL}/room/${roomName}/leave`, {
            'credentials': 'include',
            method: 'POST',
        }).then(async result => {
            if (result.status === 400) {
                const error = await result.json()
                if (error.result === "RoomDoesNotExist") {
                    dispatch(roomDoesNotExistError())
                }
            }
        })
    }

    interface GenerateScenarioResponse {
        scenarioName: string
    }

    const generateScenarioIfNeeded = async (scenario: string) => {
        if (scenario === 'GENERATE') {
            return await fetch(`${BASE_URL}/scenarios/generate`, {
                'credentials': 'include',
                method: 'POST'
            }).then(res => res.json())
                .then(res => res as GenerateScenarioResponse)
                .then(res => res.scenarioName)
        } else {
            return scenario;
        }
    };

    const createRoom = async (roomName: string, scenario: string) => {
        return fetch(`${BASE_URL}/room`, {
            'credentials': 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                roomName: roomName,
                scenarioName: await generateScenarioIfNeeded(scenario)
            })
        }).then(async result => {
            dispatch(clearErrors())
            if (result.status === 200) {
                dispatch(updateRoomName(roomName))
                setLocation(`/room/${roomName}`)
            }
            if (result.status === 400) {
                const error = await result.json()
                if (error.result === "DuplicatedRoomName") {
                    dispatch(duplicatedRoomNameError())
                }
                if (error.result === "NameCannotBeBlank") {
                    dispatch(roomWithBlankName())
                }
                if (error.result === "ScenarioDoesNotExist") {
                    dispatch(scenarioDoesNotExist())
                }
                if (error.result === "UserWithoutName") {
                    setLocation('/')
                }
            }
        })
    }

    const startGame = async (roomName: string) => {
        return fetch(`${BASE_URL}/game/${roomName}`, {
            'credentials': 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            }
        }).then(async () => {
            dispatch(clearErrors())
        })
    }

    const answerQuestion = async (roomName: string, roundType: string, answer: string, questionId: string) => {
        return fetch(`${BASE_URL}/game/${roomName}/answer`, {
            'credentials': 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                roundType: roundType,
                answer: answer,
                questionId: questionId
            })
        })
    }

    const choosePlayerToAnswer = async (roomName: string, chosenPlayer: string) => {
        return fetch(`${BASE_URL}/game/${roomName}/choosePlayer`, {
            'credentials': 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                userId: chosenPlayer
            })
        })
    }

    const availableScenarios = async () => {
        return fetch(`${BASE_URL}/scenarios`, {
            'credentials': 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(async (result) => {
            if (result.status === 200) {
                const response = await result.json()
                dispatch(updateAvailableScenarios(response.scenarios))
            }
        })
    }

    return {
        currentUser: currentUser,
        chooseUsernameAndAvatar: chooseUsernameAndAvatar,
        joinRoom: joinRoom,
        leaveRoom: leaveRoom,
        createRoom: createRoom,
        getRoom: getRoom,
        startGame: startGame,
        answerQuestion: answerQuestion,
        choosePlayerToAnswer: choosePlayerToAnswer,
        availableScenarios: availableScenarios
    }
}
