import {useEffect} from "react"
import {turnOffBackgroundMusic} from "../../reducers/appReducer"
import {useAppDispatch} from "../../reducers/store"
import {AreYouReadyPage} from "./AreYouReadyPage"

export const AreYouReadyPageContainer = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(turnOffBackgroundMusic())
    }, [])

    return <AreYouReadyPage/>
}