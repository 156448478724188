import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store.ts";
import {User} from "./userReducer.ts";
import {CosmonadaRow} from "../components/organism/CosmonadaBoard.tsx";

interface Answer {

}

export interface UserAnswer {
    userId: string,
    username: string,
    avatarUrl: string,
    answer: Answer,
    timeForAnswerInMs: number
}

interface Question {
    id: string;
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correctAnswer: string | null;
}

export interface MillionairesQuestion extends Question {
    text: string;
}

export interface OneOfTenQuestion extends Question {
    question: string;
}

export interface GuessTheSongQuestion extends Question {
    url: string;
}

export interface CosmonadaQuestion extends Question {
    question: string;
}

export interface UserWithScore {
    user: User;
    score: number;
}

export interface LastAnswer {
    answer: string;
    wrong: boolean;
    index: string;
}

export interface CosmonadaRows {
    rows: CosmonadaRow[];
    lastAnswer: LastAnswer | null;
}

export interface Room {
    name: string;
    ownerId: string;
    users: UserWithScore[];
    state: string;
    roundType: string | null;
    question: Question | null;
    answers: UserAnswer[];
    timer: string | undefined;
    playerAskedToChooseAnotherPlayer: User | undefined;
    playerChosenToAnswer: User | undefined;
    cosmonadaRows: CosmonadaRows
}

export interface RoomState {
    roomName: string | undefined
    room: Room | undefined
}

const initialState: RoomState = {
    roomName: undefined,
    room: undefined
}

export const roomSlice = createSlice({
    name: 'roomSlice',
    initialState: initialState,
    reducers: {
        updateRoomName: (state, action: PayloadAction<string>) => {
            state.roomName = action.payload
        },
        updateRoom: (state, action: PayloadAction<Room | undefined>) => {
            state.room = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {updateRoomName, updateRoom} = roomSlice.actions

export const selectRoomName = (state: RootState) => state.roomSlice.roomName
export const selectRoom = (state: RootState) => state.roomSlice.room

export default roomSlice.reducer

