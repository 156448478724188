import {Input} from '../atom/Input.tsx';
import {EditableAvatar} from '../molecule/EditableAvatar.tsx';
import {useEffect, useState} from 'react';
import {ChooseAvatar} from '../molecule/ChooseAvatar.tsx';
import {AnimatePresence, motion} from 'framer-motion';
import {Appear} from "../animations/Appear.tsx";
import {LargeText} from "../atom/LargeText.tsx";
import {ChooseUsernameAndAvatarErrors} from "../../reducers/errorReducer.ts";

interface Props {
    url: string,
    placeholder: string,
    inputValue?: string,
    onInputChanged: (input: string) => void,
    onAvatarUrlChanged: (url: string) => void,
    errors?: ChooseUsernameAndAvatarErrors | undefined
}

const availableAvatars = [
    '/avatars/1.svg',
    '/avatars/2.svg',
    '/avatars/3.svg',
    '/avatars/4.svg',
    '/avatars/5.svg',
];

export const EditableAvatarWithInput = ({
                                            url,
                                            placeholder,
                                            onInputChanged,
                                            onAvatarUrlChanged,
                                            inputValue,
                                            errors
                                        }: Props) => {
    const [isEditingAvatar, setIsEditingAvatar] = useState<boolean>(false);
    const [avatarUrl, setAvatarUrl] = useState<string>(url);

    useEffect(() => {
        setAvatarUrl(url)
    }, [url]);

    const onAvatarChosen = (avatarUrl: string) => {
        setAvatarUrl(avatarUrl);
        onAvatarUrlChanged(avatarUrl);
    };

    return <div className="flex flex-col justify-center items-center gap-4">
        <AnimatePresence>
            {
                isEditingAvatar && <motion.div
                    className="h-1/3 md:h-1/4 absolute z-10"
                    initial={{
                        x: -1000,
                    }}
                    animate={{
                        x: 0,
                    }}
                    exit={{
                        x: 1000,
                    }}
                    transition={{
                        ease: 'easeIn',
                        duration: 0.3,
                    }}
                >
                    <ChooseAvatar avatars={availableAvatars} setIsEditingAvatar={setIsEditingAvatar}
                                  onAvatarChosen={onAvatarChosen}
                                  chosenAvatar={avatarUrl}></ChooseAvatar>
                </motion.div>
            }
        </AnimatePresence>
        <EditableAvatar url={avatarUrl} onClick={() => setIsEditingAvatar(true)}></EditableAvatar>
        <Input onInputChanged={onInputChanged} placeholder={placeholder} value={inputValue}></Input>
        {
            errors?.blankName ? <Appear><LargeText textColor={'text-red'}
                                                   text={errors.blankName}/></Appear> : <></>
        }
    </div>;
};
