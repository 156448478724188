import {BlurredBackground} from '../atom/BlurredBackground.tsx';
import {LargeGradientText} from './LargeGradientText.tsx';
import {useKeyboardShortcut} from "../../hooks/useKeyboardShortcut.ts";
import useSound from "use-sound";

export type Color = 'BLUE' | 'RED' | 'GREEN' | 'YELLOW'

interface Props {
    text?: string;
    color: Color;
    onClick?: () => void;
    isClicked?: boolean;
    className?: string;
    users?: string[];
    isCorrectAnswer?: boolean;
}

const colorToKey = (color: Color): string => {
    switch (color) {
        case "BLUE":
            return "b"
        case "RED":
            return "r"
        case "GREEN":
            return "g"
        case "YELLOW":
            return "y"
    }
}

export const AnswerButton = ({
                                 text,
                                 color,
                                 onClick,
                                 isClicked = false,
                                 users = [],
                                 className,
                                 isCorrectAnswer,
                             }: Props) => {

    const [playButtonClicked] = useSound('/button_click.wav', { volume: 0.5, interrupt: true });

    const handleClick = () => {
        playButtonClicked()
        onClick && onClick();
    };

    useKeyboardShortcut({
        key: colorToKey(color),
        onKeyPressed: handleClick
    })

    return <BlurredBackground color={color.toLowerCase()}>
        <button
            className={
                `z-10 ${users.length === 0 ? 'p-3 md:p-6' : 'p-2 md:p-4'} bg-${isClicked ? `${color.toLowerCase()} text-primary-bg` : 'primary-bg text-white'} border-2 border-${color.toLowerCase()} h-full w-full text-lg md:text-xl rounded-3xl ${className}`
            }
            onClick={handleClick}>
            {
                isCorrectAnswer ? (
                    <div className="flex justify-between items-center">
                        <img className="h-8 w-8 invisible" src="/correct.svg"></img>
                        <p>{text}</p>
                        <img className="h-8 w-8" src="/correct.svg"></img>
                    </div>
                ) : (
                    <p>{text}</p>
                )
            }
            <div className="flex flex-wrap">
                {users.map((user, index) => (
                    <LargeGradientText className="pr-2" key={index} text={user}/>
                ))}
            </div>
        </button>
    </BlurredBackground>;
};
