import {AnswerButtons, AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {Timer} from '../../../molecule/Timer.tsx';
import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {VeryLargeGradientText} from '../../../molecule/VeryLargeGradientText.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    question: string;
    answers: AnswerButtonsProps;
}

export const StepFour = ({question, answers}: Props) => {

    usePlaySound('/millionaires/intro_4.mp3', 0.6)

    return <div className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText text={'Na samym dole zobaczysz ile czasu zostało do końca pytania.'}/>
        <VeryLargeGradientText className="mx-auto" text={question}/>
        <AnswerButtons {...answers} clickedButton={'GREEN'}/>
        <Timer name={question} totalTimeInSeconds={5} className="mx-auto"/>
    </div>;
};
