import {CosmonadaRoundPage} from "./CosmonadaRoundPage.tsx";
import {useSelector} from "react-redux";
import {CosmonadaQuestion, selectRoom} from "../../../reducers/roomReducer.ts";
import {useQuizApi} from "../../../client/useQuizApi.ts";
import {useEffect, useState} from "react";
import {CosmonadaRow} from "../../organism/CosmonadaBoard.tsx";
import {useTimer} from "use-timer";
import {usePlaySingleSound} from "../../../hooks/usePlaySound.ts";
import {selectUser} from "../../../reducers/userReducer.ts";

export const CosmonadaPageContainer = () => {

    const room = useSelector(selectRoom)
    const {answerQuestion} = useQuizApi()
    const [guessed, setGuessed] = useState<string[]>([])
    const [previousQuestion, setPreviousQuestion] = useState<string | undefined>(undefined)
    const {start, reset, time, status} = useTimer()
    const answerAppearedSound = usePlaySingleSound('/button_click.wav', 0.5);
    const user = useSelector(selectUser)

    useEffect(() => {
        if (room && ['COSMONADA_BOARD_UPDATED', 'QUESTION_ASKED'].includes(room.state) && room.cosmonadaRows.lastAnswer) {
            if (!room.cosmonadaRows.lastAnswer.wrong && !guessed.includes(room.cosmonadaRows.lastAnswer.index)) {
                answerAppearedSound()
                setGuessed([...guessed, room.cosmonadaRows.lastAnswer.index])
            }
        }

    }, [room]);

    useEffect(() => {
        if (room?.state === 'QUESTION_FINISHED') {
            start()
        }
    }, [room?.state]);

    useEffect(() => {
        if (status === 'RUNNING') {
            const index = (room?.cosmonadaRows.rows.length ?? 0) - time;
            if (index >= 0 && index < (room?.cosmonadaRows.rows.length ?? -1)) {
                const rowIndex = room?.cosmonadaRows.rows[index].index.toString() ?? '-1'
                if (!guessed.includes(rowIndex)) {
                    answerAppearedSound()
                    setGuessed([...guessed, rowIndex])
                }
            } else {
                stop()
                reset()
            }
        }
    }, [time])

    useEffect(() => {
        const question = room?.question as CosmonadaQuestion;
        if(question?.question !== previousQuestion) {
            setGuessed([])
            setPreviousQuestion(question?.question)
        }

    }, [room, previousQuestion]);

    if (!room || !room.cosmonadaRows || !room.question) {
        return <></>
    }

    const question = room.question as CosmonadaQuestion;

    const handleOnClick = (answer: string) => {
        answerQuestion(room.name, room.roundType!!, answer, room.question!!.id)
    }

    const onlyGuessedRevealed = (rows: CosmonadaRow[]): CosmonadaRow[] => {
        return rows.map(row => {
            if (guessed.includes(row.index.toString() ?? '-1')) {
                return row;
            } else {
                return {
                    index: row.index,
                    answer: null,
                    score: null
                }
            }
        })
    }

    const isPlayerAnswering = user?.userId === room?.playerChosenToAnswer?.userId
    const isSummary = room.state === 'QUESTION_FINISHED'

    return <CosmonadaRoundPage question={question.question}
                               rows={onlyGuessedRevealed(room.cosmonadaRows.rows)}
                               wrongAnswer={(room.state === 'COSMONADA_BOARD_UPDATED' && room.cosmonadaRows.lastAnswer?.wrong) ?? false}
                               handleAnswer={handleOnClick}
                               isPlayerAnswering={isPlayerAnswering}
                               username={room.playerChosenToAnswer?.name}
                               isSummary={isSummary}
                               lastAnswer={room?.cosmonadaRows.lastAnswer?.answer ?? undefined}
    />
}