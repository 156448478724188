import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store.ts";

export interface AppState {
    backgroundMusicPlaying: boolean;
    anchor?: string;
}

const initialState: AppState = {
    backgroundMusicPlaying: false
}

export const appSlice = createSlice({
    name: 'appSlice',
    initialState: initialState,
    reducers: {
        turnOnBackgroundMusic: (state) => {
            state.backgroundMusicPlaying = true
        },
        turnOffBackgroundMusic: (state) => {
            state.backgroundMusicPlaying = false
        },
        updateAnchor: (state, action: PayloadAction<string | undefined>) => {
            state.anchor = action.payload
        }
    },
})

export const selectBackgroundMusicPlaying = (state: RootState) => state.appSlice.backgroundMusicPlaying

export const selectAnchor = (state: RootState) => state.appSlice.anchor

export const {turnOnBackgroundMusic, turnOffBackgroundMusic, updateAnchor} = appSlice.actions

export default appSlice.reducer

