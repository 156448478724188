import { ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';


interface Props {
  children: ReactElement | ReactElement[];
  className?: string;
}

export const Appear = ({children, className} : Props) => {
  return <AnimatePresence mode="wait">
    <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: 'spring',
                  opacity: { ease: 'easeIn' },
                  duration: 1.2,
                }}
                exit={{
                  y: 400,
                  opacity: 0
                }}
                className={className}
    >
      {children}
    </motion.div>
  </AnimatePresence>
}
