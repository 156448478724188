import { Avatar } from '../atom/Avatar.tsx';
import { GridLayout } from '../templates/GridLayout.tsx';
import { GradientBackgroundAvatar } from './GradientBackgroundAvatar.tsx';
import { useState } from 'react';

interface Props {
  avatars: string[];
  chosenAvatar?: string;
  setIsEditingAvatar?: (isEditing: boolean) => void;
  onAvatarChosen?: (avatar: string) => void;
}

export const ChooseAvatar = ({ avatars, chosenAvatar = avatars[0], setIsEditingAvatar, onAvatarChosen }: Props) => {

  const [chosen, setChosen] = useState(chosenAvatar);

  const onClick = (index: number) => {
    setChosen(avatars[index]);
    setIsEditingAvatar && setIsEditingAvatar(false);
    onAvatarChosen && onAvatarChosen(avatars[index]);
  };

  return <div className="flex justify-center items-center rounded-xl h-full">
    {
      <GridLayout className="h-full backdrop-blur-primary-bg rounded-2xl z-10 p-4">
        {avatars.map((avatarUrl, index) => (
          avatars[index] === chosen ? <GradientBackgroundAvatar key={avatarUrl} className="w-5/6 h-5/6" rounded avatarUrl={avatarUrl}
                                                       onClick={() => onClick(index)} />
            : <Avatar key={avatarUrl} url={avatarUrl} className="cursor-pointer w-5/6 h-5/6" onClick={() => onClick(index)}></Avatar>
        ))}
      </GridLayout>
    }
  </div>;
};
