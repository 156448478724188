import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store.ts";

export interface User {
    userId: string;
    name: string;
    avatarUrl: string;
}

export interface UserState {
    user: User | undefined
}

const initialState: UserState = {
    user: undefined
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<User | undefined>) => {
            state.user = action.payload
        },
    },
})

export const {updateUser} = userSlice.actions

export const selectUser = (state: RootState) => state.userSlice.user

export default userSlice.reducer

