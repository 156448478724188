import {ReactNode} from 'react';
import {LevitatingImage} from '../molecule/LevitatingImage.tsx';

interface Props {
    text?: string;
    onClick?: () => void;
    className?: string;
    imageUrl?: string | null;
    children?: ReactNode;
}

export const Button = ({text, onClick, imageUrl, className, children}: Props) => {

    const handleClick = () => {
        onClick && onClick()
    }

    return <button className={`bg-primary-bg text-white h-full w-full text-lg md:text-xl rounded-2xl ${className}`}
                   onClick={handleClick}>
        <div className="flex justify-center items-center relative">
            {text}

            {imageUrl && <LevitatingImage imageUrl={imageUrl}/>}
            {
                children
            }
        </div>
    </button>;
};
