import {FC} from 'react';
import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {Appear} from '../../../animations/Appear.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

export const StepOne: FC = () => {

    usePlaySound('/cosmonada/intro_1.mp3', 1.0)

    return <Appear className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText
            text={
                'W tej rundzie zawodnicy będą zgadywali jak odpowiedzieli ankietowani.'
            }
        />
    </Appear>;
};
