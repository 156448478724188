import {User} from '../molecule/UserWithAvatar.tsx';
import {RoomBeforeGameStart} from '../organism/RoomBeforeGameStart.tsx';
import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';

interface Props {
    ownerId: string;
    currentUser: User;
    roomName: string;
    handleUserEditClick: () => void;
    users: User[];
    handleStart: () => void;
    handleExit: () => void;
}

export const RoomPage = ({currentUser, ownerId, roomName, handleUserEditClick, users, handleStart, handleExit}: Props) => {
    return <CenteredContentLayout>
        <RoomBeforeGameStart handleUserEditClick={handleUserEditClick}
                             roomName={roomName}
                             currentUser={currentUser}
                             ownerId={ownerId}
                             users={users}
                             handleStart={handleStart}
                             handleExit={handleExit}
        />
    </CenteredContentLayout>;
};
