import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';
import {LevitatingImage} from '../molecule/LevitatingImage.tsx';
import {GradientTitle} from '../molecule/GradientTitle.tsx';
import {usePlaySound} from "../../hooks/usePlaySound.ts";

export const AreYouReadyPage = () => {

    usePlaySound('/ready.mp3', 0.8)

    return <CenteredContentLayout>
        <GradientTitle text={'Gotowi?'}/>
        <LevitatingImage animation={{
            from: 32,
            to: -32,
            duration: 2,
        }} className="h-16 w-16 md:h-32 md:w-32" imageUrl={'/astronaut.svg'}/>
    </CenteredContentLayout>;
};
