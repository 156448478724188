import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {CosmonadaBoard} from "../../../organism/CosmonadaBoard.tsx";
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    question: string;
}

export const StepFour = ({question}: Props) => {

    usePlaySound('/cosmonada/intro_4.mp3', 1.0)

    return <div className="h-[85%] md:h-3/4 w-full md:w-3/4 text-center flex flex-col gap-8">
        <TypewritingText
            text={'Na ekranie pojawi się pytanie oraz tablica z miejscami na odpowiedzi.'}/>
        <CosmonadaBoard
            isSummary={false}
            rows={[{index: '1'}, {index: '2'}, {index: '3'}, {index: '4'}]}
            question={question}
            wrongAnswer={false}
            handleAnswer={() => {
            }}
            isPlayerAnswering={true}
        />
    </div>
};
