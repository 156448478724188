import {InvitationPage} from "./InvitationPage.tsx";
import {useAppDispatch} from "../../reducers/store.ts";
import {updateUser} from "../../reducers/userReducer.ts";
import {useLocation, useParams} from "wouter";
import {updateAnchor} from "../../reducers/appReducer.ts";
import {useEffect} from "react";

export const InvitationPageContainer = () => {

    const dispatch = useAppDispatch()

    const [_, setLocation] = useLocation()
    const params = useParams()

    useEffect(() => {
        dispatch(updateUser(undefined))
    }, []);

    const handleJoinAsSpectator = () => {
        dispatch(updateUser({
            name: '',
            userId: 'spectator',
            avatarUrl: '/avatars/1.svg'
        }))
        const roomName = params.room
        setLocation(`/room/${roomName}`)
    }

    const handleJoinAsPlayer = () => {
        const roomName = params.room
        dispatch(updateUser(undefined))
        dispatch(updateAnchor(`/room/${roomName}`))
        setLocation(`/room/${roomName}`)
    }

    return <InvitationPage handleJoinAsPlayer={handleJoinAsPlayer} handleJoinAsSpectator={handleJoinAsSpectator}/>
}