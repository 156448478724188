import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {Appear} from "../../../animations/Appear.tsx";
import {useTimer} from "use-timer";
import {useEffect, useState} from "react";
import {GradientTypewritingText} from "../../../atom/GradientTypewritingText.tsx";
import {usePlaySoundIf} from "../../../../hooks/usePlaySound.ts";

export const Joke = () => {

    const {time, start} = useTimer();
    const [isFirstReady, setFirstReady] = useState<boolean>(true)
    const [isSecondReady, setSecondReady] = useState<boolean>(false)

    usePlaySoundIf('/cosmonada/joke_1.mp3', 1.0, () => isFirstReady, [isFirstReady])
    usePlaySoundIf('/cosmonada/joke_2.mp3', 1.0, () => isSecondReady, [isSecondReady])

    useEffect(() => {
        start()
    }, []);

    useEffect(() => {
        if (time > 3) {
            setFirstReady(false);
            setSecondReady(true);
        }
    }, [time]);

    return <Appear className="flex flex-col w-5/6 gap-4 md:gap-8 items-center">
        <div className="text-center">
            <TypewritingText text={'– Co to jest autosugestia?'}/>

            {
                time > 3 ? <>
                    <GradientTypewritingText
                        text={'– Jest to sugerowanie komuś, że ma auto.'}
                    />
                </> : <></>
            }
        </div>

        {
            time > 5 ?
                <div className="flex flex-col justify-center items-center w-full">
                    <Appear>
                        <img className="h-64 aspect-square" src='/astronaut-joke.svg'/>
                    </Appear>
                </div>
                : <></>
        }

    </Appear>
};