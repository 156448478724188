import {OneOfNPage} from './OneOfNPage.tsx';
import {useSelector} from 'react-redux';
import {OneOfTenQuestion, Room, selectRoom} from '../../../reducers/roomReducer.ts';
import {useQuizApi} from '../../../client/useQuizApi.ts';
import {mapUserAnswersToRecord} from '../../../utils/mapUserAnswersToRecord.ts';
import {selectUser, User} from '../../../reducers/userReducer.ts';
import useSound from "use-sound";
import {useEffect, useState} from "react";

export const OneOfNPageContainer = () => {

    const room = useSelector(selectRoom);
    const currentUser = useSelector(selectUser)
    const {answerQuestion, choosePlayerToAnswer} = useQuizApi();
    const [question, setQuestion] = useState<OneOfTenQuestion | null>(null)

    const [play, {stop}] = useSound('/millionaires/thinking-background.mp3', {volume: 0.1, interrupt: true});

    useEffect(() => {
        if (room?.state === 'QUESTION_ASKED' && currentUser && currentUser.userId === 'spectator') {
            play()
        } else {
            stop()
        }

        return () => stop();
    }, [room?.state, play]);

    useEffect(() => {
        if (room?.question && room?.question !== question) {
            setQuestion((room?.question) as OneOfTenQuestion)
        }
    }, [room?.question]);

    if (!room || !currentUser) {
        return <></>;
    }

    const isUserWaitingForAnotherPlayer = (room: Room, currentUser: User): boolean => {
        if (room.state === 'PLAYER_CHOOSING_ANOTHER_PLAYER') {
            return room.playerAskedToChooseAnotherPlayer?.userId !== currentUser.userId
        } else {
            return room.playerChosenToAnswer?.userId !== currentUser.userId
        }
    }

    return <OneOfNPage
        onClick={user => choosePlayerToAnswer(room.name, user.userId)}
        waiting={isUserWaitingForAnotherPlayer(room, currentUser)}
        mode={room.state === 'PLAYER_CHOOSING_ANOTHER_PLAYER' ? 'CHOOSING' : 'ANSWERING'}
        username={(room.state === 'PLAYER_CHOOSING_ANOTHER_PLAYER' ? room.playerAskedToChooseAnotherPlayer?.name : room.playerChosenToAnswer?.name) ?? ''}
        users={room.users.map(u => u.user)}
        question={question?.question ?? null}
        n={room.users.length}
        answers={{
            onAnswerSelected: (answer: string) => {
                answerQuestion(room.name, room.roundType ?? '', answer, question?.id ?? '');
            },
            correctAnswer: question?.correctAnswer ?? undefined,
            answers: {
                answerToUsers: 'QUESTION_FINISHED' === room.state ? mapUserAnswersToRecord(room.answers) : {},
            },
            green: {
                text: room?.question?.optionA ?? '',
            },
            blue: {
                text: room?.question?.optionB ?? '',
            },
            yellow: {
                text: room?.question?.optionC ?? '',
            },
            red: {
                text: room?.question?.optionD ?? '',
            },
        }}
    />
}
