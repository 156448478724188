import { useEffect } from 'react';
import { cn } from '../../utils/cn.ts';

interface Props {
  timeAlreadyPassed?: number,
  totalTimeInSeconds: number,
  className?: string,
  name: string // to make sure it rerenders
}

export const Timer = ({ name, className, timeAlreadyPassed = 0, totalTimeInSeconds }: Props) => {

  useEffect(() => {
    const timerBar = document.getElementById(`timerBar-${name}`);
    if (!timerBar) return; // Exit if the timer bar isn't found

    const totalTime = totalTimeInSeconds * 1000; // Total time in milliseconds, adjust as needed

    let timePassed = timeAlreadyPassed * 1000;

    const intervalId = setInterval(() => {

      timePassed += 10;
      const timeLeft = totalTime - timePassed;
      const percentageTimeLeft = (timeLeft / totalTime) * 100;

      timerBar.style.width = percentageTimeLeft + '%';

      if (timeLeft <= 0) {
        // Handle timer complete logic here
      }
    }, 10);

    return () => {
      clearInterval(intervalId);
      timerBar.style.width = '100%';
    };
  }, [name]);


  return <div id={`timerBar-${name}`} data-testid="timer-bar" className={cn('w-full h-2.5 bg-primary-gradient rounded-full block', className)}>
    <br />
  </div>;
};
