import {User} from "../molecule/UserWithAvatar.tsx";
import {Place, Result, ScoreCard} from "../molecule/ScoreCard.tsx";

interface CardProps {
    user: User,
    place: Place,
    score: Result
}

interface Props {
    scores: CardProps[];
    className?: string;
}

export const ScoreCards = ({scores}: Props) => {
    return <div className="w-3/4 lg:w-1/2 xl:1/3 2xl:1/4 overflow-y-auto">
        {
            scores.map((result, index) => (
                <ScoreCard className="mt-4 md:mt-8" key={index} place={result.place} score={result.score} user={result.user}/>
            ))
        }
    </div>
}