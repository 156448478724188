import { motion } from 'framer-motion';
import { cn } from '../../utils/cn.ts';

interface LevitateAnimation {
  from: number;
  to: number;
  duration: number;
}

interface Props {
  imageUrl: string;
  className?: string;
  animation?: LevitateAnimation;
}

export const LevitatingImage = ({ imageUrl, className, animation = { from: 4, to: -4, duration: 2 } }: Props) => {
  return <motion.div
    initial={{ y: animation.from }}
    animate={{ y: animation.to }}
    transition={{
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'mirror',
      duration: animation.duration,
    }}>
    <img
      className={cn('h-6 w-6 md:h-8 md:w-8 ml-2', className)}
      src={imageUrl} />
  </motion.div>;
};
