import {UnknownScore} from "./UnknownScore.tsx";
import {VeryLargeGradientText} from "./VeryLargeGradientText.tsx";

interface Props {
    index: string;
    text?: string | null;
    score?: string | null;
}

export const CosmonadaBoardRow = ({index, text, score}: Props) => {
    return <div className="flex justify-between m-4 md:m-8">
        <VeryLargeGradientText text={index}></VeryLargeGradientText>
        <VeryLargeGradientText className="truncate ml-4 uppercase"
                               text={text ?? "_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _"}/>
        {score !== undefined && score !== null ? <VeryLargeGradientText text={score}/> : <UnknownScore/>}
    </div>
}