import { cn } from '../../utils/cn.ts';

interface Props {
  text: string;
  textColor?: string | null;
  className?: string;
}

export const SmallText = ({text, textColor = 'text-white', className}: Props) => {
  return <p className={cn(`text-xs md:text-sm ${textColor ? textColor : ''}`, className)}>{text}</p>
}
