import {FC} from 'react';
import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {Appear} from '../../../animations/Appear.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

export const StepOne: FC = () => {
    usePlaySound('/millionaires/intro_1.mp3', 0.6)

    return <Appear className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText text={'Na ekranie pojawi się pytanie oraz 4 odpowiedzi.'}/>
    </Appear>;
};
