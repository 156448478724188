import {useEffect, useState} from "react";
import {useKeyboardShortcut} from "../../hooks/useKeyboardShortcut.ts";

interface Props {
    placeholder?: string;
    onClick: (answer: string) => void;
    defaultText?: string;
}

export const FloatingInput = ({placeholder, onClick, defaultText}: Props) => {

    const [text, setText] = useState<string>('')

    useEffect(() => {
        setText(defaultText ?? '')
    }, [defaultText]);

    useKeyboardShortcut({
        key: 'Enter', onKeyPressed: () => onClick(text)
    })

    return <div className="relative z-0 text-center">
        <input type="text" id="floating_standard"
               className="block py-2.5 px-0 w-full text-xl text-white bg-transparent border-0 border-b-2 border-white appearance-none dark:text-white dark:border-white dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
               placeholder=" "
               value={defaultText}
               onChange={e => setText(e.target.value)}
        />
        <label htmlFor="floating_standard"
               className="absolute -translate-x-1/2 w-full text-xl text-white dark:text-white duration-300 transform -translate-y-6 scale-75 top-2 -z-10 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:center-auto">
            {placeholder}
        </label>
        <img onMouseDown={() => onClick(text)} onTouchStart={() => onClick(text)} tabIndex={-1}
             className={`absolute z-1- top-0 h-8 w-8 right-0 ${text !== '' ? 'visible' : 'hidden'} cursor-pointer`}
             src="/correct.svg"/>
    </div>
}