import {FC} from 'react';
import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {Appear} from '../../../animations/Appear.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

export const StepOne: FC = () => {

    usePlaySound('/guess_the_song/intro_1.mp3', 1.0)

    return <Appear className="flex flex-col w-5/6 mx-auto text-center">
        <TypewritingText text={'W tej rundzie zawodnicy usłyszą  piosenkę oraz zobaczą cztery możliwe odpowiedzi.'}/>
    </Appear>;
};
