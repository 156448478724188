import {GradientBoard} from "../molecule/GradientBoard.tsx";
import {CosmonadaBoardRow} from "../molecule/CosmonadaBoardRow.tsx";
import {FloatingInput} from "../atom/FloatingInput.tsx";
import {CosmonadaWrongAnswer} from "../molecule/CosmonadaWrongAnswer.tsx";
import {LevitatingImage} from "../molecule/LevitatingImage.tsx";
import {LargeText} from "../atom/LargeText.tsx";
import {VeryLargeText} from "../atom/VeryLargeText.tsx";
import {VeryLargeGradientText} from "../molecule/VeryLargeGradientText.tsx";

export interface CosmonadaRow {
    index: string;
    answer?: string | null;
    score?: string | null;
}

interface Props {
    rows: CosmonadaRow[];
    question: string;
    wrongAnswer: boolean;
    lastAnswer?: string;
    handleAnswer: (answer: string) => void;
    isPlayerAnswering: boolean;
    text?: string;
    username?: string;
    isSummary: boolean;
}

export const CosmonadaBoard = ({
                                   rows,
                                   question,
                                   wrongAnswer,
                                   lastAnswer,
                                   handleAnswer,
                                   isPlayerAnswering,
                                   text,
                                   username,
                                   isSummary
                               }: Props) => {
    return <GradientBoard>
        <div className="flex place-content-center py-4 text-center">
            <VeryLargeText text={question}></VeryLargeText>
        </div>
        <div className="flex flex-col h-full justify-around">
            <div>
                {
                    rows.map(row => (
                        <CosmonadaBoardRow key={row.index} index={row.index} text={row.answer} score={row.score}/>
                    ))
                }
            </div>
            <div className="flex flex-col justify-around flex-1 w-3/4 md:w-1/3 mx-auto">
                {
                    !isSummary && (
                        isPlayerAnswering ?
                            <FloatingInput defaultText={text} onClick={handleAnswer} placeholder={'Wpisz hasło...'}/>
                            : <div className="text-center">
                                <LevitatingImage
                                    animation={{from: 0, to: -26, duration: 2}}
                                    className="h-16 w-16 md:h-24 md:w-24 mx-auto"
                                    imageUrl={'/astronaut.svg'}
                                />
                                <LargeText text={`Odpowiada ${username}...`}/>
                            </div>
                    )
                }
                {lastAnswer && wrongAnswer &&
                    <div className="flex items-center justify-center">
                        <VeryLargeGradientText text={lastAnswer}/>
                    </div>
                }
                {wrongAnswer && <CosmonadaWrongAnswer/>}
                <div/>
            </div>
        </div>
    </GradientBoard>
}