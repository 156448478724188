import {useCopyToClipboard} from "../../hooks/copyToClipboard.ts";

interface Props {
    path: string;
}

export const ShareButton = ({path}: Props) => {

    const copyToClipboard = useCopyToClipboard()

    return <img onClick={() => {
        copyToClipboard(location.origin + path)
        window.alert("Zaproszenie skopiowane!")
    }} className="w-4 h-4 cursor-pointer" src="/share.svg"/>
}