import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App.tsx';
import './index.scss';
import '@fontsource/itim';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './reducers/store.ts';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://b074307875ac5d0d120ea4e748947e9f@o4507940697735168.ingest.de.sentry.io/4507940800102480",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://quiz.proszowski.dev"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
createRoot(container!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <main>
          <App />
          <div id="stars" />
          <div id="stars2" />
          <div id="stars3" />
          <div
            className="grayscale hidden border-red border-yellow border-blue border-green text-4xl grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4"></div>
        </main>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
