import {GradientTitle} from '../../../molecule/GradientTitle.tsx';
import {useTimer} from 'use-timer';
import {useLocation} from 'wouter';
import {useEffect} from 'react';
import {StepThree} from './StepThree.tsx';
import {answers} from '../../millionaires/introduction/MillionairesIntroductionPage.tsx';
import {StepOne} from './StepOne.tsx';
import {StepTwo} from './StepTwo.tsx';
import {CenteredContentLayout} from "../../../templates/CenteredContentLayout.tsx";

export const OneOfNIntroductionPage = () => {

    const {time, start} = useTimer();
    const [_, setLocation] = useLocation();

    useEffect(() => {
        start();
    }, [start]);

    useEffect(() => {
        if (time >= 25) {
            setLocation('/are-you-ready');
        }
    }, [time]);

    return <CenteredContentLayout className="gap-2 md:gap-8">
        {time <= 25 ? <GradientTitle text={'Jeden z sześciu'}/> : <></>}
        {(time < 6) ? <StepOne/> : <></>}
        {(time >= 6 && time < 16) ? <StepTwo/> : <></>}
        {(time >= 16 && time <= 25) ?
            <StepThree answers={answers} question={'Które z wymienionych miast jest stolicą Francji?'}/> : <></>
        }
    </CenteredContentLayout>;
};
