import { useEffect, useState } from 'react';

interface Props {
    placeholder: string;
    onInputChanged?: (input: string) => void;
    value?: string;
}

export const Input = ({ placeholder, onInputChanged, value, ...rest }: Props) => {

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    const handleInputChanged = (changed: string) => {
        setInputValue(changed);
        onInputChanged && onInputChanged(changed);
    };

    return <input
        {...rest}
        className={`bg-white rounded-3xl pl-2 py-1 md:pl-4 md:py-2 focus:ring-0 focus:ring-offset-0 focus:border-transparent outline-none`}
        placeholder={placeholder}
        onChange={(e) => handleInputChanged(e.target.value)}
        value={inputValue}
    />;
};
