import {selectRoomName, updateRoom} from '../reducers/roomReducer.ts';
import {useAppDispatch} from '../reducers/store.ts';
import {useSelector} from 'react-redux';
import {useEffect} from "react";
import {useQuizApi} from "./useQuizApi.ts";

export const useRoomWebsocket = () => {

    const dispatch = useAppDispatch();
    const BASE_URL = import.meta.env.VITE_QUIZ_API_URL;
    const roomName = useSelector(selectRoomName);
    const {getRoom} = useQuizApi()

    useEffect(() => {
        if (roomName) {
            getRoom(roomName)
        }
    }, [roomName]);

    function connectSSE() {
        const eventSource = new EventSource(BASE_URL + `/stream/${roomName}`);

        if (eventSource) {
            eventSource.addEventListener("RoomUpdated", event => {
                console.log(event)
                dispatch(updateRoom(JSON.parse(event.data)))
            })
        }

        eventSource.onerror = function() {
            // Retry connection after a short delay
            setTimeout(() => {
                eventSource.close();
                connectSSE();
            }, 1000);
        };
    }

    useEffect(() => {
        connectSSE();
    }, [roomName]);

};

