import {BlurredBackground} from "../atom/BlurredBackground.tsx";
import {ReactNode} from "react";

interface Props {
    children?: ReactNode | ReactNode[]
}

export const GradientBoard = ({children}: Props) => {
    return <BlurredBackground withoutHover={true}>
        <div className="z-10 bg-primary-bg h-[98%] w-[98%] rounded-2xl">
            {children}
        </div>
    </BlurredBackground>
}