import { CenteredContentLayout } from '../../templates/CenteredContentLayout.tsx';
import { VeryLargeGradientText } from '../../molecule/VeryLargeGradientText.tsx';
import { AnswerButtons, AnswerButtonsProps } from '../../organism/AnswerButtons.tsx';
import { Timer } from '../../molecule/Timer.tsx';
import { GradientTitle } from '../../molecule/GradientTitle.tsx';

interface Props {
  question: string,
  answers: AnswerButtonsProps
}

export const MillionairesRoundPage = ({ question, answers }: Props) => {
  return <CenteredContentLayout className="gap-4 md:gap-16">
    <GradientTitle text={'Miliarderzy'} className="mx-auto" />
    <VeryLargeGradientText className="mb-4 md:mb-0 w-3/4 mx-auto text-center" text={question} />
    <AnswerButtons className={'w-3/4'} {...answers} />
    <div className="flex justify-center w-3/4 mt-4 md:mt-0">
      <Timer name={question} totalTimeInSeconds={10} />
    </div>
  </CenteredContentLayout>;
};
