import { ReactNode } from 'react';
import { cn } from '../../utils/cn.ts';

interface Props {
  children?: ReactNode,
  className?: string;
}

export const GradientTextMask = ({ children, className, ...rest }: Props) => {
  return <div {...rest} className={cn('bg-clip-text text-transparent bg-primary-gradient w-fit py-2', className)}>
    {children}
  </div>;
};
