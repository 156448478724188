import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "./store.ts";

export interface CreateRoomErrors {
    chooseName?: string,
    chooseScenario?: string
}

export interface JoinRoomErrors {
    chooseRoom: string | undefined,
}

export interface ChooseUsernameAndAvatarErrors {
    blankName: string | undefined;
}

export interface Errors {
    createRoomErrors: CreateRoomErrors | undefined;
    joinRoomErrors: JoinRoomErrors | undefined;
    chooseUsernameAndAvatarErrors: ChooseUsernameAndAvatarErrors | undefined;
}

export const initialState: Errors = {
    createRoomErrors: undefined,
    joinRoomErrors: undefined,
    chooseUsernameAndAvatarErrors: undefined
}

export const errorSlice = createSlice({
    name: 'errorSlice',
    initialState: initialState,
    reducers: {
        duplicatedRoomNameError: (state) => {
            state.createRoomErrors = {
                chooseName: 'Eh... nazwa pokoju jest już zajęta - wybierz inną.'
            }
        },
        scenarioDoesNotExist: (state) => {
            state.createRoomErrors = {
                chooseScenario: 'Wybierz scenariusz ;)'
            }
        },
        roomDoesNotExistError: (state) => {
            state.createRoomErrors = undefined
            state.joinRoomErrors = {
                chooseRoom: 'Taki pokój nie istnieje.'
            }
        },
        clearErrors: (state) => {
            state.createRoomErrors = undefined
            state.joinRoomErrors = undefined
            state.chooseUsernameAndAvatarErrors = undefined
        },
        usernameWithBlankName: (state) => {
            state.chooseUsernameAndAvatarErrors = {
                blankName: 'Jak się nazywasz?'
            }
        },
        roomWithBlankName: (state) => {
            state.createRoomErrors = {
                chooseName: 'Podaj nazwę pokoju.'
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    duplicatedRoomNameError,
    scenarioDoesNotExist,
    roomDoesNotExistError,
    clearErrors,
    usernameWithBlankName,
    roomWithBlankName
} = errorSlice.actions

export const selectCreateRoomErrors = (state: RootState) => state.errorsSlice.createRoomErrors
export const selectJoinRoomErrors = (state: RootState) => state.errorsSlice.joinRoomErrors
export const selectChooseUsernameAndAvatarErrors = (state: RootState) => state.errorsSlice.chooseUsernameAndAvatarErrors

export default errorSlice.reducer
