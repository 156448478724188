import {GradientTitle} from "../molecule/GradientTitle.tsx";
import {ShareButton} from "../molecule/ShareButton.tsx";
import {ExitButton} from "../molecule/ExitButton.tsx";

interface Props {
    title: string;
    roomName?: string;
    handleExit: () => void;
}

export const TitleWithActions = ({roomName, title, handleExit}: Props) => {
    return <div className="flex justify-center items-center gap-4">
        <GradientTitle text={title}></GradientTitle>
        {roomName && <ShareButton path={`/invitation/${roomName}`}/>}
        <ExitButton onClick={handleExit}/>
    </div>
}