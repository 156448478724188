import {useEffect} from "react";

interface UseKeyboardShortcutProps {
    key: string
    onKeyPressed: () => void;
}

export const useKeyboardShortcut = ({
                                        key,
                                        onKeyPressed
                                    }: UseKeyboardShortcutProps) => {
    useEffect(() => {
        const keyDownHandler = (e: globalThis.KeyboardEvent) => {
            if (e.key === key) {
                e.preventDefault();
                onKeyPressed();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [onKeyPressed]);
};