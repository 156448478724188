import {CreateOrJoinPage} from './CreateOrJoinPage.tsx';
import {useSelector} from 'react-redux';
import {selectUser} from '../../reducers/userReducer.ts';
import {useEffect, useState} from 'react';
import {useLocation} from 'wouter';
import {useQuizApi} from '../../client/useQuizApi.ts';
import {clearErrors, selectJoinRoomErrors} from '../../reducers/errorReducer.ts';
import {useAppDispatch} from '../../reducers/store.ts';

export const CreateOrJoinPageContainer = () => {

    const [username, setUsername] = useState('');
    const [avatar, setAvatar] = useState('');

    const [_, setLocation] = useLocation();
    const {joinRoom} = useQuizApi()
    const user = useSelector(selectUser)
    const errors = useSelector(selectJoinRoomErrors)
    const dispatch = useAppDispatch()
    const {currentUser} = useQuizApi()

    useEffect(() => {
        currentUser()
    }, []);

    useEffect(() => {
        dispatch(clearErrors())
        if (user) {
            setUsername(user.name)
            setAvatar(user.avatarUrl)
        }
    }, [user]);

    return <CreateOrJoinPage
        username={username}
        avatar={avatar}
        handleClick={(room: string) => {
            joinRoom(room)
        }}
        handleEditClick={() => {
            setLocation('/')
        }}
        errors={errors}
    />
}
