import { useEffect } from 'react';

export interface Option {
    label: string;
    value: string;
}

interface Props {
    options: Option[];
    onSelectChanged?: (value: string) => void;
}

export const Select = ({ options, onSelectChanged, ...rest }: Props) => {

    useEffect(() => {
        if (options && options.length > 0) {
            onSelectChanged && onSelectChanged(options[0].value);
        }
    }, [options]);

    const handleOnSelect = (value: string) => {
        onSelectChanged && onSelectChanged(value);
    };

    return <select
        {...rest}
        onChange={(e) => handleOnSelect(e.target.value)}
        className={`bg-white rounded-3xl p-1.5 md:p-2.5 focus:ring-0 focus:ring-offset-0 focus:border-transparent outline-none`}>
        {
            options.map((option, index) => (
                <option key={index} label={option.label} value={option.value}></option>
            ))
        }
    </select>;
};
