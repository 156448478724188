import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {VeryLargeGradientText} from '../../../molecule/VeryLargeGradientText.tsx';
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    question: string;
    answers: AnswerButtonsProps;
    text?: string
}

export const StepThree = ({question, answers}: Props) => {

    usePlaySound('/millionaires/intro_3.mp3', 0.6)

    return <div className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText text={'Zaznaczona odpowiedź nie może już zostać zmieniona.'}/>
        <VeryLargeGradientText className="mx-auto" text={question}/>
        <AnswerButtons {...answers} clickedButton={'GREEN'}/>
    </div>;
};
