import { CreateRoomPage } from './CreateRoomPage.tsx';
import { useQuizApi } from '../../client/useQuizApi.ts';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableScenarios } from '../../reducers/scenariosReducer.ts';
import { clearErrors, selectCreateRoomErrors } from '../../reducers/errorReducer.ts';
import { useAppDispatch } from '../../reducers/store.ts';

export const CreateRoomPageContainer = () => {

  const { createRoom, availableScenarios } = useQuizApi();
  const scenarios = useSelector(selectAvailableScenarios);
  const errors = useSelector(selectCreateRoomErrors);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearErrors());
    availableScenarios();
  }, []);

  return <CreateRoomPage
    errors={errors}
    scenarios={scenarios}
    handleClick={(roomName, scenario) => {
      createRoom(roomName, scenario);
    }}
  />;
};
