import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement | ReactElement[];
}

export const AnimateFromTop = ({ children }: Props) => {
  return <AnimatePresence mode="wait">
    <motion.div initial={{ opacity: 0, y: -400 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  type: 'spring',
                  opacity: { ease: 'easeIn' },
                  duration: 1.2,
                }}
                exit={{
                  y: 400,
                  opacity: 0
                }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
};
