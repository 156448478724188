import { AnswerButton, Color } from '../molecule/AnswerButton.tsx';
import { useEffect, useState } from 'react';
import { cn } from '../../utils/cn.ts';

interface ButtonProps {
  text: string;
}

interface Answers {
  answerToUsers: Record<string, string[]>;
}

export interface AnswerButtonsProps {
  green: ButtonProps,
  blue: ButtonProps,
  yellow: ButtonProps,
  red: ButtonProps,
  clickable?: boolean,
  clickedButton?: Color,
  className?: string,
  answers?: Answers,
  onAnswerSelected?: (answer: string) => any,
  correctAnswer?: string
}

const colorToOption = (color: Color) => {
  switch (color) {
    case 'GREEN':
      return 'A';
    case 'BLUE':
      return 'B';
    case 'YELLOW':
      return 'C';
    case 'RED':
      return 'D';
  }
};

export const AnswerButtons = ({
                                green,
                                blue,
                                yellow,
                                red,
                                clickedButton,
                                className,
                                answers,
                                onAnswerSelected,
                                clickable = true,
                                correctAnswer,
                              }: AnswerButtonsProps) => {

  const [clicked, setClicked] = useState<Color | undefined>(clickedButton);

  useEffect(() => {
    if(correctAnswer) {
      setClicked(undefined)
    }
  }, [correctAnswer]);

  const handleClick = (color: Color) => {
    if (clicked === undefined && clickable && !correctAnswer) {
      setClicked(color);
      if (onAnswerSelected) {
        onAnswerSelected(colorToOption(color));
      }
    }
  };

  return <div className={cn('grid grid-cols-1 sm:grid-cols-2 gap-8 w-full', className)}>
    <AnswerButton text={green.text} color={'GREEN'} isClicked={clicked === 'GREEN'}
                  onClick={() => handleClick('GREEN')}
                  users={answers?.answerToUsers['A'] ?? []}
                  isCorrectAnswer={correctAnswer === colorToOption('GREEN')}
    ></AnswerButton>

    <AnswerButton text={blue.text} color={'BLUE'} isClicked={clicked === 'BLUE'}
                  onClick={() => handleClick('BLUE')}
                  users={answers?.answerToUsers['B'] ?? []}
                  isCorrectAnswer={correctAnswer === colorToOption('BLUE')}
    ></AnswerButton>

    <AnswerButton text={yellow.text} color={'YELLOW'} isClicked={clicked === 'YELLOW'}
                  onClick={() => handleClick('YELLOW')}
                  users={answers?.answerToUsers['C'] ?? []}
                  isCorrectAnswer={correctAnswer === colorToOption('YELLOW')}
    ></AnswerButton>

    <AnswerButton text={red.text} color={'RED'} isClicked={clicked === 'RED'}
                  onClick={() => handleClick('RED')}
                  users={answers?.answerToUsers['D'] ?? []}
                  isCorrectAnswer={correctAnswer === colorToOption('RED')}
    ></AnswerButton>
  </div>;
};
