import useSound from 'use-sound';
import {useSelector} from 'react-redux';
import {selectBackgroundMusicPlaying, turnOffBackgroundMusic} from '../reducers/appReducer.ts';
import {useEffect} from 'react';
import {selectUser} from "../reducers/userReducer.ts";
import {useAppDispatch} from "../reducers/store.ts";

export const useBackgroundMusic = () => {
    const [play, {sound}] = useSound('/background.mp3', {volume: 0.1, interrupt: true});
    const backgroundMusicPlaying = useSelector(selectBackgroundMusicPlaying);
    const dispatch = useAppDispatch()

    const user = useSelector(selectUser)

    useEffect(() => {
        dispatch(turnOffBackgroundMusic())
    }, []);

    useEffect(() => {
        if (backgroundMusicPlaying && sound && user && user.userId === 'spectator') {
            console.log('turning on background music...')
            play();
            sound.fade(0.0, 0.1, 100);
            return () => stop();
        }
    }, [backgroundMusicPlaying, sound, user]);

    useEffect(() => {
        if (!backgroundMusicPlaying && sound && user && user.userId === 'spectator') {
            console.log('turning off background music...')
            sound.fade(0.1, 0.0, 8000);
        }
    }, [backgroundMusicPlaying, sound, user]);
};
