import React from 'react';
import {AnimateFromTop} from '../animations/AnimateFromTop.tsx';
import {cn} from '../../utils/cn.ts';


interface Props {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
}

export const CenteredContentLayout = ({children, className}: Props) => {
    return <AnimateFromTop>
        <div className={cn('flex flex-col h-screen w-screen justify-center items-center gap-16 md:gap-32', className)}>
            {children}
        </div>
    </AnimateFromTop>;
};
