import {GradientTitle} from '../molecule/GradientTitle.tsx';
import {EditableAvatarWithInput} from '../organism/EditableAvatarWithInput.tsx';
import {ContinueButton} from '../molecule/ContinueButton.tsx';
import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';
import {ChooseUsernameAndAvatarErrors} from "../../reducers/errorReducer.ts";

interface Props {
    setUsername: (username: string) => void,
    username: string,
    setAvatarUrl: (avatarUrl: string) => void,
    avatarUrl: string,
    handleClick: () => void,
    errors?: ChooseUsernameAndAvatarErrors | undefined
}

export const WelcomePage = ({setUsername, setAvatarUrl, avatarUrl, username, handleClick, errors}: Props) => {

    return <CenteredContentLayout className="gap-8 md:gap-16">
        <GradientTitle text={'Cosmo Quiz'}></GradientTitle>
        <EditableAvatarWithInput
            onInputChanged={setUsername}
            onAvatarUrlChanged={setAvatarUrl}
            url={avatarUrl}
            inputValue={username}
            placeholder={'Wpisz swoje imię...'}
            errors={errors}
        />
        <ContinueButton onClick={() => handleClick()} text={'LECIMY'}></ContinueButton>

    </CenteredContentLayout>;
};
