import {CenteredContentLayout} from '../templates/CenteredContentLayout.tsx';
import {GradientTitle} from '../molecule/GradientTitle.tsx';
import {User} from '../molecule/UserWithAvatar.tsx';
import {Place, Result} from '../molecule/ScoreCard.tsx';
import {LevitatingImage} from '../molecule/LevitatingImage.tsx';
import {LargeText} from '../atom/LargeText.tsx';
import {ScoreCards} from "../organism/ScoreCards.tsx";

export interface RoundResult {
    user: User,
    place: Place,
    score: Result
}

interface Props {
    results: RoundResult[];
}

export const RoundSummaryPage = ({results}: Props) => {

    return <CenteredContentLayout className="gap-8 md:gap-12">
        <GradientTitle text={'Podsumowanie'}></GradientTitle>
        <ScoreCards scores={results}/>
        <LevitatingImage animation={{
            from: 0,
            to: -48,
            duration: 2,
        }} className="h-16 w-16 md:h-24 md:w-24" imageUrl={'/astronaut.svg'}/>
        <LargeText text={"Oczekiwanie na rozpoczęcie rundy..."}/>
    </CenteredContentLayout>;
};
