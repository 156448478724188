import {VeryLargeGradientText} from '../../molecule/VeryLargeGradientText.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../organism/AnswerButtons.tsx';
import {Timer} from '../../molecule/Timer.tsx';

interface Props {
    question: string,
    answers: AnswerButtonsProps,
    waiting?: boolean
}

export const AnsweringQuestion = ({question, answers, waiting}: Props) => {
    return <div className="flex flex-col text-center h-full gap-8 md:gap-16">
        <div className="w-full mt-4 md:mt-0">
            <VeryLargeGradientText className="mx-auto" text={question}/>
        </div>
        <AnswerButtons className="mt-4 md:mt-0" {...answers} />
        {
            !waiting && <div className="flex justify-center mt-4 md:mt-0">
                <Timer name={question} totalTimeInSeconds={10}/>
            </div>
        }
    </div>;
};
